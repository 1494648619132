export const matchConfig = (match) => {
  const team1 = match?.config?.topLabels?.[0]?.name;
  const team2 = match?.config?.topLabels?.[1]?.name;
  const result = match?.matchData?.info?.[0]?.result?.[0] || {};
  const resultText = result?.result_type || '';
  const matchDate = match?.config?.date
    ? new Date(match?.config?.date).toLocaleDateString('en-GB', { month: 'short', day: 'numeric', timeZone: 'UTC' }).toUpperCase()
    : null;
  const score1 =
    match?.config?.topLabels?.[0]?.score?.runs && match?.config?.topLabels?.[0]?.score?.wickets !== null
      ? `${match?.config?.topLabels?.[0]?.score?.runs}-${match?.config?.topLabels?.[0]?.score?.wickets}`
      : null;
  const score2 =
    match?.config?.topLabels?.[1]?.score?.runs && match?.config?.topLabels?.[1]?.score?.wickets !== null
      ? `${match?.config?.topLabels?.[1]?.score?.runs}-${match?.config?.topLabels?.[1]?.score?.wickets}`
      : null;
  function getOrganizedMedia(match) {
    const mediaArray = match?.config?.medias || [];
    const organizedMedia = {
      highlightVideo: null,
      individualHighlightClips: [],
      fullMatchVideos: [],
    };
    mediaArray.forEach((media) => {
      switch (media.videoCategory) {
        case 'HIGHLIGHT_SUMMARY':
          organizedMedia.highlightVideo = media.mediaUrl;
          break;
        case 'INDIVIDUAL_HIGHLIGHT_CLIP':
          organizedMedia.individualHighlightClips.push(media.mediaUrl);
          break;
        case 'FULL_MATCH_VIDEO':
          organizedMedia.fullMatchVideos.push(media.mediaUrl);
          break;
        default:
          console.warn('Unhandled videoCategory:', media.videoCategory);
      }
    });

    return organizedMedia;
  }
  const organizedMedia = getOrganizedMedia(match);
  const highlightVideo = organizedMedia.highlightVideo || null;
  const individualHighlightClips = organizedMedia.individualHighlightClips || [];
  const fullMatchVideos = organizedMedia.fullMatchVideos || [];
  const backendMediaArray =
    individualHighlightClips[individualHighlightClips.length - 1] || highlightVideo || fullMatchVideos[0] || null;

  const config = {
    topChip: {
      LiveMatch: match?.config?.topChip?.LiveMatch,
      bgcolor: '',
      fontcolor: '',
    },
    tournament: {
      tournamentName: match?.config?.tournament?.tournamentName || null,
      tournamentLogo: '',
      tournamentId: match?.config?.tournament?.tournamentId || null,
      bgcolor: '',
      fontcolor: '',
    },
    topLabels: [
      {
        name: team1,
        logo: '',
        score: score1,
        overplayed: match?.config?.topLabels?.[0]?.overplayed || null,
        totalover: match?.config?.topLabels?.[0]?.totalover || null,
        bgcolor: '',
        fontcolor: '',
        onClick: {
          actions: [
            {
              action: 'navigate',
              deepLink: '',
              params: { teamId: team1.teamId || '' },
              baseUrl: 'https://qa.gully6.com',
              queryParams: { teamId: team1.teamId || '' },
            },
          ],
        },
      },
      {
        name: team2,
        logo: '',
        score: score2,
        overplayed: match?.config?.topLabels?.[1]?.overplayed || null,
        totalover: match?.config?.topLabels?.[1]?.totalover || null,
        bgcolor: '',
        fontcolor: '',
        onClick: {
          actions: [
            {
              action: 'navigate',
              deepLink: '',
              params: { teamId: team2.teamId || '' },
              baseUrl: 'https://qa.gully6.com',
              queryParams: { teamId: team2.teamId || '' },
            },
          ],
        },
      },
    ],
    date: matchDate,
    matchStatus: match?.config?.matchStatus?.matchtype,
    BottomHeader: {
      topScorer: '',
      topScorerRuns: '',
      bgcolor: '',
      fontcolor: '',
    },
    engagement: {
      likes: '',
      views: match?.config?.engagement?.views || null,
      share: true,
    },
    matchId: match?.matchId || '',
    matchResult: `${resultText}`,
    medias: backendMediaArray,
  };

  return config;
};

export default matchConfig;
