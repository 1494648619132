// PrivacyPolicies.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import Navbar from '../../components/Navbar';
import Myysports from '../../assets/myysports.png';
import MainWidthWrapper from '../../components/MainWidthWrapper';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const PrivacyPolicy = () => {
  const [searchParams] = useSearchParams();
  const isWebView = searchParams.get('webview') === 'true';
  const navigate = useNavigate();

  const handlelogoClick = () => {
    navigate('/');
  };

  const handleBatClick = () => {
    navigate('/');
  };

  const handleInfoclick = () => {
    navigate('/about-us');
  };

  const handleprivacyClick = () => {
    navigate('/privacy-policy');
  };

  const rows = [
    {
      column1: 'purpose',
      column2: 'Personal Data',
      column3: 'Processing Grounds',
    },
    {
      column1: 'Analytics',
      column2: (
        <ul>
          <li>Usage Data</li>
          <li>Device Information</li>
          <li>Geographical Data</li>
          <li>Device Identifiers</li>
        </ul>
      ),
      column3: 'Our legitimate interest to  analyze users’ behavior in  order to further improve our  Services',
    },
    {
      column1: 'User Contact',
      column2: (
        <ul>
          <li>First and last name </li>
          <li> Home address (street, zip code, city, country)</li>
          <li>Email address</li>
          <li> Phone number</li>
          <li> Phone number</li>
          <li> Location of where the App was last used</li>
        </ul>
      ),
      column3:
        'Your consent when you  submit the personal data  using a contact form to get in  contact with us; The performance of our agreement with you',
    },
    {
      column1: 'Registration and authentication',
      column2: (
        <ul>
          <li>First and Last Name</li>
          <li>Email Address</li>
          <li>Phone Number</li>
          <li>Social Media Account</li>
          <li>Address</li>
        </ul>
      ),
      column3:
        'Your consent when you use a  third party other than us to  provide authentication services; The performance of our agreement with you. ',
    },
    {
      column1: 'Marketing',
      column2: 'Usage Data',
      column3:
        'Your consent when you opt-in  to marketing cookies (see  section on Cookies below); Our legitimate interests to  analyze users’ behavior in  order to further improve our  Services.',
    },
  ];
  return (
    <>
      {!isWebView ? (
        <Navbar
          onlogoClick={handlelogoClick}
          onBatClick={handleBatClick}
          onInfoclick={handleInfoclick}
          onPrivacyClick={handleprivacyClick}
        />
      ) : null}
      <MainWidthWrapper>
        <Box
          sx={{
            fontFamily: 'Arial, sans-serif',
            backgroundColor: '#f4f4f4',
            margin: 0,
            padding: '10px',
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
          }}
        >
          <Typography paragraph>
            <>
              <p style={{ textAlign: 'center' }}>
                <strong>
                  <u>
                    <span style={{ fontSize: '12pt' }}>PRIVACY POLICY&nbsp;</span>
                  </u>
                </strong>
              </p>
              <p>
                <span style={{ fontSize: '12pt' }}>
                  <b>MyySports.com</b> (“MyySports”, “we”, “us”) [
                  <b>Myysports A66, First Floor, FIEE Complex, Okhla Phase ll, New Delhi, 110020</b>] is committed to the privacy
                  of its users (“you”, “user”).{' '}
                </span>
              </p>
              <p></p>
              <p>
                <span style={{ fontSize: '12pt' }}>
                  As a user of the <b>MyySports.com,Gully6.com,GullyBall.com</b> websites and related services (collectively, the
                  “Services”), we know you want to remain in control of your personal information. We realize that earning and
                  retaining your trust is of paramount importance and it&aposs a responsibility we take very seriously, every day.
                </span>
              </p>
              <p>
                <span style={{ fontSize: '12pt' }}>
                  That&aposs why we have provided this Privacy Policy, which sets forth our policies regarding the collection, use
                  and protection of the personal information of those using our Services in accordance with applicable law,
                  including the General Data Protection Regulation (“GDPR”).
                </span>
              </p>
              <p>
                <span style={{ fontSize: '12pt' }}>
                  This Privacy Policy inter alia explains the following:
                  <ul>
                    <li>
                      <p>
                        <span style={{ fontSize: '12pt' }}>
                          What personal information we collect from you and on what legal basis we may use it and how we use it
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: '12pt' }}>With whom (if anyone) your personal information is shared</span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: '12pt' }}>
                          Your rights regarding our processing of your personal data and how you can opt-out of receiving our
                          promotional emails
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: '12pt' }}>
                          What safeguards we have in place to protect your personal information
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: '12pt' }}>Other information you should know about your privacy</span>
                      </p>
                    </li>
                  </ul>
                </span>
              </p>

              <ol>
                <li>
                  <strong>
                    <span style={{ fontSize: '12pt' }}>Personal Information</span>
                  </strong>
                  <p>
                    <span style={{ fontSize: '11pt' }}>
                      Personal information or personal data means information that can be linked to a specific individual
                      (“Personal Data”), such as name, address, telephone number, e-mail address and credit card number. This
                      Privacy Policy explains how MyySports collects, uses and discloses information from and about you when you
                      use any of the Services.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontSize: '11pt' }}>
                      We encourage you to review our Privacy Policy, and become familiar with it, but you should know that we do
                      not sell or rent our user&aposs personal information to third parties.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontSize: '11pt' }}>
                      Please note that we review our Privacy Policy from time to time, and we may make periodic changes to the
                      policy in connection with that review. Therefore, you may wish to bookmark this page and/or periodically
                      review this page to make sure you have the latest version.
                    </span>
                  </p>
                </li>
                <li>
                  <strong>
                    <span style={{ fontSize: '12pt' }}>Legal Basis</span>
                  </strong>
                  <p>
                    <span style={{ fontSize: '11pt' }}>
                      We process your Personal Data only based on one or more of the following processing grounds as listed in the
                      GDPR:
                    </span>
                  </p>

                  <ul>
                    <li>
                      <p>
                        <span style={{ fontSize: '11pt' }}>Your consent;</span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: '11pt' }}>The performance of a contract to which you are a party;</span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: '11pt' }}>
                          The protection of your vital interests or those of another natural person;
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: '11pt' }}>Our legitimate interests or those of a third party; and/or</span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: '11pt' }}>The compliance with legal obligations to which we are subject.</span>
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>
                    <span style={{ fontSize: '12pt' }}>Consent</span>
                  </strong>
                  <p>
                    <span style={{ fontSize: '11pt' }}>
                      Where our processing activities rely on your consent, such consent can always be withdrawn at any time.
                      Withdrawal of your consent does not affect the lawfulness of processing based on consent before the consent
                      was withdrawn.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontSize: '11pt' }}>
                      With regard to children who are below the age of 16 or, in some EU Member States, a lower age to a minimum
                      age of 13, the consent given is only valid if it is given or authorized by the holder of parental
                      responsibility over the child. We shall make reasonable efforts to verify consent is given or authorized by
                      the holder of parental responsibility over the child, for example by asking the age of the data subject and
                      to state they have been granted adequate authorization to give their consent.
                    </span>
                  </p>
                </li>
                <li>
                  <strong>
                    <span style={{ fontSize: '12pt' }}>Processing Purposes</span>
                  </strong>
                  <ul>
                    <li>
                      <p>
                        <span style={{ fontSize: '11pt' }}> to provide our Services; </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: '11pt' }}>to broadcast livestream content;</span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: '11pt' }}> to send you information which you requested;</span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: '11pt' }}>
                          {' '}
                          to perform and manage your orders for products and/or services offered by us; • to manage your account;
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: '11pt' }}>
                          to send you information about new features or new products or services that may be relevant for you
                          based on Myysports’ products and services previously purchased by you;
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: '11pt' }}>
                          to better understand the visitors and users of our Services and what interests them;
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: '11pt' }}>
                          to improve the quality and relevance of our Services for our visitors, for example, to customize your
                          user experience, tailor your searches and show you advertisements that may be of interest;
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: '11pt' }}>
                          {' '}
                          to analyze your use of products and services made available by us for quality control, product
                          development and marketing purposes;
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: '11pt' }}>to organize joint marketing events with third parties;</span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: '11pt' }}>to prevent and detect fraud;</span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: '11pt' }}>to protect us in the event of a legal dispute</span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: '11pt' }}>to train our staff;</span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: '11pt' }}>
                          to comply with statutory obligations imposed on us, such as obligations regarding company administration
                          and tax law;
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: '11pt' }}>to respond to enforcement requests</span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: '11pt' }}>
                          to protect our rights and interests, or those of our users or third parties
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: '11pt' }}>to display third party content;</span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: '11pt' }}>to process your information requests;</span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: '11pt' }}>to test the performance and features of our Services;</span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: '11pt' }}>
                          to manage your registration and authentication (either directly with us, or through third parties); and
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: '11pt' }}>for other purposes we have made known to you beforehand.</span>
                      </p>
                    </li>
                  </ul>
                  <ol>
                    <li>
                      <strong>
                        <span style={{ fontSize: '12pt' }}>Types of Information we may process</span>
                      </strong>
                      <p>
                        <span style={{ fontSize: '11pt' }}>We may collect your Personal Data in the following manner(s):</span>
                      </p>

                      <ul>
                        <li>
                          <p>
                            <span style={{ fontSize: '11pt' }}>
                              your portrait and body as a whole, when we make recordings of a sporting match in which you
                              participated and your consent was given to do so;
                            </span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <span style={{ fontSize: '11pt' }}>
                              your first name, last name, contact details (address, e-mail address and telephone number), date of
                              birth, gender and billing information when you create an account for the use of our Services;{' '}
                            </span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <span style={{ fontSize: '11pt' }}>
                              your account, your profile image, contact details, the matches you viewed, users you followed,
                              videos you uploaded, social posts you created, replied and liked, content reports and your team and
                              tournament ownership to measure your user activity;
                            </span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <span style={{ fontSize: '11pt' }}>
                              the data made available by you in connection to an account for the use of our Services. If you do
                              not provide us with certain Personal Data, we will not be able to provide (part of) our Services to
                              you that require an account;
                            </span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <span style={{ fontSize: '11pt' }}>
                              he data we automatically collect from your device, which may include your IP address, device type,
                              unique device identification numbers, browser-type (such as Firefox, Safari, or Internet Explorer),
                              your Internet Service Provider (ISP), your operating system and carrier, technical information which
                              includes the way your device interacts with our website, details of any referring website or exit
                              pages as well as broad geographic location;
                            </span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <span style={{ fontSize: '11pt' }}>
                              we may receive your Personal Data from third parties or affiliates, which includes social media
                              providers. Such data includes name, profile picture, gender, birthday, email address, town or
                              district and any other information you have chosen to make available. We do not store or use any
                              data related to the friends you are connected with on a social media site. We may also access
                              information from social media providers about your use of an app that we run on their website
                            </span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <span style={{ fontSize: '11pt' }}>
                              when you call our team or receive a call from our team, the call may be recorded or monitored; and
                            </span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <span style={{ fontSize: '11pt' }}>
                              when you contact us through our Services, for example via chat, the messages will be recorded and
                              monitored.{' '}
                            </span>
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li>
                      In the table below we have included a non-exhaustive list of the types of Personal Data we currently collect
                      and process:
                      <TableContainer component={Paper}>
                        <Table>
                          <TableBody>
                            {rows.map((row, index) => (
                              <TableRow key={index}>
                                <TableCell align="left" style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                  {row.column1}
                                </TableCell>
                                <TableCell align="left" style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                  {row.column2}
                                </TableCell>
                                <TableCell align="left">{row.column3}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <p>
                        <span style={{ fontSize: '11pt' }}>
                          You have the ability to control what information is sent to us by apps by changing the apps’ settings or
                          changing the permissions on your device.
                        </span>
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>
                    <span style={{ fontSize: '12pt' }}>Content provided by third parties </span>
                  </strong>
                  <p>
                    <span style={{ fontSize: '11pt' }}>
                      The Services may show and include content which is hosted on external platforms, directly on the page
                      visible during your use of the Services, for example an advertisement displayed in an advertisement banner.
                      The privacy policy and cookie policy of such third parties is applicable to your visit to such a third party
                      website.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontSize: '11pt' }}>
                      We also support sign-in using third party authenticators, such as Google or Apple log-in. In such cases,
                      such third parties may also process your personal data in addition to us. Please refer to that third party’s
                      privacy policy for their use of your personal data.
                    </span>
                  </p>
                </li>
                <li>
                  <strong>
                    <span style={{ fontSize: '12pt' }}>Period of retention </span>
                  </strong>
                  <p>
                    <span style={{ fontSize: '11pt' }}>
                      We will retain your Personal Data, including cookies, only for as long as is necessary for the purposes set
                      out in this Privacy Policy. In some cases, applicable law may require us to retain your Personal Data for a
                      certain amount of time. We will retain and use your Personal Data to the extent necessary to comply with our
                      legal obligations (for example, if we are required to retain your data to comply with applicable laws),
                      resolve disputes, and enforce our legal agreements and policies. Such information will be retained until the
                      statute of limitations commences. In addition, we retain personal information which we are obligated to
                      retain for administrative and tax reasons for the duration as required under mandatory statutory law.
                    </span>
                  </p>
                </li>
                <li>
                  <strong>
                    <span style={{ fontSize: '12pt' }}>Transfer and sharing your Personal Data </span>
                  </strong>
                  <p>
                    <span style={{ fontSize: '11pt' }}>
                      Your information, including Personal Data, may be transferred to and maintained on computers located outside
                      of your state, province, country or other governmental jurisdiction where the data protection laws may
                      differ from those of your jurisdiction.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontSize: '11pt' }}>
                      Please note that your information entered into your account is stored on the servers of our hosting service
                      provider, Amazon Web Services in India. We have provided appropriate safeguards as we have entered into a
                      processing agreement based on the standard contractual clauses as made available by the European Commission,
                      pursuant to the model published on June 4th, 2021. Upon your request, we will provide you with a copy of
                      these standard contractual clauses.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontSize: '11pt' }}>
                      We will take all steps reasonably necessary to ensure that your data is treated securely and no transfer of
                      your Personal Data will take place to an organization or a country outside of the EER unless there are
                      adequate controls in place including the security of your data and other personal information.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontSize: '11pt' }}>
                      We may share your personal information with selected partners in accordance with the GDPR.
                    </span>
                  </p>
                </li>
                <li>
                  <strong>
                    <span style={{ fontSize: '12pt' }}>security</span>
                  </strong>
                  <p>
                    <span style={{ fontSize: '11pt' }}>
                      Security We maintain state of the industry security measures to avoid unauthorized access to, alteration,
                      disclosure or loss of your Personal Data. We process Personal Data in a manner that ensures appropriate
                      security of the Personal Data by using commercially acceptable industry standards
                    </span>
                  </p>
                </li>
                <li>
                  <strong>
                    <span style={{ fontSize: '12pt' }}>Your rights regarding your Personal Data </span>
                  </strong>
                  <p>
                    <span style={{ fontSize: '11pt' }}>You have the right:</span>
                  </p>

                  <ul>
                    <li>
                      <p>
                        <span style={{ fontSize: '11pt' }}>
                          to correct, amend, delete, or limit the use of your Personal Data. We will take all reasonable steps to
                          comply with your requests to exercise your rights in relation to your data. You can update your Personal
                          Data directly within your account settings section. Please contact us if you are unable to make the
                          required changes;
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: '11pt' }}>
                          to access and receive a copy of the Personal Data we hold about you;
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: '11pt' }}> to rectify any Personal Data held about you that is inaccurate;</span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ fontSize: '11pt' }}>to request the deletion of Personal Data held about you. </span>
                      </p>
                    </li>
                  </ul>
                  <p>
                    <span style={{ fontSize: '11pt' }}>
                      If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our
                      systems, please contact us using the information listed below. Please note that we may ask you to verify
                      your identity before responding to such requests.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontSize: '11pt' }}>
                      You have the right to data portability for the information you provide to MyySports. You can request to
                      obtain a copy of your Personal Data in a commonly used electronic format so that you can manage and move it.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontSize: '11pt' }}>
                      You have the right to file a complaint with the supervisory authority, in our case the Dutch Data Protection
                      Authority in the Netherlands. [In what EU country has MyySports chosen its seat? That country’s supervising
                      authority will be the leading supervisory authority].
                    </span>
                  </p>
                </li>
                <li>
                  <strong>
                    <span style={{ fontSize: '12pt' }}>Data Deletion</span>
                  </strong>
                  <p>
                    <span style={{ fontSize: '11pt' }}>
                      Users have the right to request the deletion of their personal data at any time. Upon receiving a valid
                      deletion request, we will:
                    </span>
                  </p>

                  <ul>
                    <li>
                      <b>Delete Personal Data:</b> Remove all personal data associated with the user from our active systems and
                      databases, unless the data is required to be retained by law or for legitimate business purposes.
                    </li>
                    <li>
                      <b>Anonymization:</b> In cases where complete deletion is not feasible (e.g., for legal or financial
                      reasons), we may anonymize the data, so it no longer identifies the user.
                    </li>
                    <li>
                      <b>Data Backup: </b>Data stored in backups will be securely retained until the backup retention period
                      expires and then will be deleted during routine backup purging processes.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>
                    <span style={{ fontSize: '12pt' }}> Opt-out</span>
                  </strong>
                  <p>
                    <span style={{ fontSize: '11pt' }}>
                      You can opt-out from receiving our promotional emails at any point by clicking on the “Unsubscribe” button
                      located at the bottom of all our emails or send an email to list{' '}
                      <a href="mailto:unsubscribe@myysports.com">unsubscribe@myysports.com</a>. If you have any further questions,
                      please do contact us.
                    </span>
                  </p>
                </li>
                <li>
                  <strong>
                    <span style={{ fontSize: '12pt' }}> Contact Us</span>
                  </strong>
                  <p>
                    <span style={{ fontSize: '11pt' }}>
                      If you have any questions or grievances about this Privacy Policy, please contact us by sending an email to:
                      <a href="mailto:compliance@myysports.com">compliance@myysports.com</a>
                    </span>
                  </p>
                  <p>
                    <span style={{ fontSize: '11pt' }}>
                      Physical mailing Address: <br />
                      MyySports <br />
                      A66, First Floor, FIEE Complex, Okhla Phase ll, New Delhi, 110020
                    </span>
                  </p>
                </li>
              </ol>

              <p>
                <span style={{ fontSize: '11pt' }}>
                  <b>DATED: 04.09.2024</b>
                  <br />
                  This Privacy Policy is subject to change, so please consult this page regularly for the latest version. We
                  reserve the right to make changes to this Privacy Policy without notifying you.
                </span>
              </p>

              <Box sx={{ display: 'flex', justifyContent: '' }}>
                <Box component={'img'} src={Myysports} alt={'gully logo here'} />
              </Box>
            </>
          </Typography>
        </Box>
      </MainWidthWrapper>
    </>
  );
};

export default PrivacyPolicy;
