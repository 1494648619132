import axios from 'axios';
import {
  setCurrentPlayingVideo,
  setIndividualHighlightClipsList,
  setSummaryAndFullMatchVideosList,
  setMatchCardThumbnail,
  setWinningMomentThumbnail,
} from '../video/video.slice';
import {
  setMatchData,
  setMatchError,
  setMatchLoading,
  setTotalMatches,
  setTeamData,
  setTeamLoading,
  setTeamError,
  setSelectedDate,
  setPlayerLoading,
  setPlayerData,
  setPlayerError,
  setViews,
} from './match.slice';
import api from '../../api/api';

export const fetchMatchData = (matchId, getTopPerformersFlag, getPartnershipRunsFlag, getRunsPerOverFlag) => {
  return async (dispatch) => {
    try {
      dispatch(setMatchLoading());

      const response = await api.get(
        `${process.env.REACT_APP_API_BASE_URL}api/v1.2.0/cricket/match/${matchId}?getTopPerformers=${getTopPerformersFlag}&psh=${getPartnershipRunsFlag}&rpo=${getRunsPerOverFlag}`
      );

      // Constructing thumbnail URLs
      const matchCardThumbnail = `https://gc-media-01.s3.ap-south-1.amazonaws.com/${matchId}/thumbnail_${matchId}_matchcard.webp`;
      const winningMomentThumbnail = `https://gc-media-01.s3.ap-south-1.amazonaws.com/${matchId}/thumbnail_${matchId}_winningmoment.webp`;

      const summaryVideo = response.data?.data?.matchMedias?.fullAndSummaryVideos?.find(
        (video) => video.videoCategory === 'HIGHLIGHT_SUMMARY'
      );
      const fullMatchVideo = response.data?.data?.matchMedias?.fullAndSummaryVideos?.find(
        (video) => video.videoCategory === 'FULL_MATCH_VIDEO'
      );
      const liveStreamVideo = response.data?.data?.matchMedias?.fullAndSummaryVideos?.find(
        (video) => video.videoCategory === 'LIVE_MATCH_STREAM'
      );

      const initVideoToPlay =
        fullMatchVideo && summaryVideo
          ? { url: summaryVideo.mediaUrl, category: 'HIGHLIGHT_SUMMARY' }
          : liveStreamVideo && response.data?.data?.isMatchLive
          ? { url: liveStreamVideo.mediaUrl, category: 'LIVE_MATCH_STREAM' }
          : {
              url: fullMatchVideo?.mediaUrl,
              category: 'FULL_MATCH_VIDEO',
            };

      // Dispatching actions
      dispatch(
        setMatchData({
          ...response.data.data,
          matchCardThumbnail,
          winningMomentThumbnail,
        })
      );
      dispatch(setMatchCardThumbnail(matchCardThumbnail));
      dispatch(setWinningMomentThumbnail(winningMomentThumbnail));
      dispatch(setSummaryAndFullMatchVideosList(response.data?.data?.matchMedias?.fullAndSummaryVideos));
      dispatch(setIndividualHighlightClipsList(response.data?.data?.matchMedias?.individualHighlightClips));
      dispatch(setViews(response.data?.data?.views));
      dispatch(setCurrentPlayingVideo(initVideoToPlay));
    } catch (error) {
      dispatch(setMatchError(error.message));
    }
  };
};

export const fetchTeamData = (teamObjectId) => {
  return async (dispatch) => {
    try {
      dispatch(setTeamLoading());
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}api/v1/cricket/team/details?teamId=${teamObjectId}&loggedInUserId=656599caff5cfc8e7489079c`
      );
      dispatch(setTeamData(response.data.data.teamDetails));
    } catch (error) {
      dispatch(setTeamError(error.message));
    }
  };
};

export const fetchPlayerData = (userName) => {
  return async (dispatch) => {
    try {
      dispatch(setPlayerLoading());
      const response = await api.get(`${process.env.REACT_APP_API_BASE_URL}api/v1/profile/user-profile?userName=${userName}`);
      const playerData = response.data.data.profileData;
      const userObjId = playerData.userObjId._id;
      dispatch(setPlayerData({ ...playerData, userObjId }));
    } catch (error) {
      dispatch(setPlayerError(error.message));
    }
  };
};

export const fetchPlayerMatches = (currentPage, pageSize, userObjId) => {
  return async (dispatch) => {
    try {
      dispatch(setMatchLoading());

      let url = `${process.env.REACT_APP_API_BASE_URL}api/v0/cricket/matches?page=${currentPage}&pageSize=${pageSize}&userObjId=${userObjId}&sortBy=matchDate&sortOrder=desc`;

      const response = await axios.get(url);
      dispatch(setTotalMatches(response.data.data));
    } catch (error) {
      dispatch(setMatchError(error.message));
    }
  };
};

export const fetchAllMatches = (currentPage, pageSize, date, tournament, userObjId) => {
  return async (dispatch) => {
    try {
      dispatch(setMatchLoading());
      let url = `${process.env.REACT_APP_API_BASE_URL}api/v0/cricket/matches?page=${currentPage}&pageSize=${pageSize}&sortBy=matchDate&sortOrder=desc`;
      if (date) {
        url += `&startDate=${date}`;
      }
      if (tournament) {
        url += `&filterBy=${tournament}`;
      }
      if (userObjId) {
        url += `&userObjId=${userObjId}`;
      }
      const response = await axios.get(url);
      dispatch(setTotalMatches(response.data.data));
    } catch (error) {
      dispatch(setMatchError(error.message));
    }
  };
};
