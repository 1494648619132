// PrivacyPolicies.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import MainWidthWrapper from '../../components/MainWidthWrapper';

const TermsAndConditions = () => {
  const [searchParams] = useSearchParams();
  const isWebView = searchParams.get('webview') === 'true';
  const navigate = useNavigate();

  const handlelogoClick = () => {
    navigate('/');
  };

  const handleBatClick = () => {
    navigate('/');
  };

  const handleInfoclick = () => {
    navigate('/about-us');
  };

  const handleprivacyClick = () => {
    navigate('/privacy-policy');
  };

  return (
    <>
      {!isWebView ? (
        <Navbar
          onlogoClick={handlelogoClick}
          onBatClick={handleBatClick}
          onInfoclick={handleInfoclick}
          onPrivacyClick={handleprivacyClick}
        />
      ) : null}
      <MainWidthWrapper>
        <Box
          sx={{
            fontFamily: 'Arial, sans-serif',
            backgroundColor: '#f4f4f4',
            margin: 0,
            padding: '10px',
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
          }}
        >
          <Typography paragraph>
            <>
              <p style={{ textAlign: 'center', margin: '5px 0' }}>
                <strong>
                  <u>
                    <span style={{ fontSize: '10.25pt' }}>TERMS OF SERVICE&nbsp;</span>
                  </u>
                </strong>
              </p>
              <p style={{ textAlign: 'center', margin: '5px 0' }}>
                <strong>
                  <u>
                    <span style={{ fontSize: '10.25pt' }}>WELCOME TO GULLY6</span>
                  </u>
                </strong>
              </p>
              <strong>
                <p style={{ margin: '5px 0' }}>
                  <span style={{ fontSize: '10.25pt' }}>These Terms of Service mandates the terms on which the users</span>
                  <span style={{ fontSize: '10.25pt' }}>&nbsp;</span>
                  <strong>
                    <span style={{ fontSize: '10.25pt' }}>(“you” or “your” or “users”)</span>
                  </strong>
                  <span style={{ fontSize: '10.25pt' }}>&nbsp;access, use and register on the website</span>
                  <strong>
                    <span style={{ fontSize: '10.25pt' }}>“gully6.com”</span>
                  </strong>
                  <span style={{ fontSize: '10.25pt' }}>&nbsp;and&nbsp;</span>
                  <strong>
                    <span style={{ fontSize: '10.25pt' }}>“Gully6 Application”</span>
                  </strong>
                  <span style={{ fontSize: '10.25pt' }}>, (collectively referred to as&nbsp;</span>
                  <strong>
                    <span style={{ fontSize: '10.25pt' }}>“Platform”)</span>
                  </strong>
                  <span style={{ fontSize: '10.25pt' }}>&nbsp;which is&nbsp;</span>
                  <span style={{ fontSize: '10.25pt' }}>owned and operated by Myy Sports Private Limited (referred as</span>
                  <strong>
                    <span style={{ fontSize: '10.25pt' }}>&nbsp;“Gully6”)</span>
                  </strong>
                  <span style={{ fontSize: '10.25pt' }}>.&nbsp;</span>
                </p>
              </strong>
              <p style={{ margin: '5px 0' }}>
                <strong>
                  <span style={{ fontSize: '10.25pt' }}>
                    PLEASE READ THESE TERMS OF SERVICE CAREFULLY. BY ACCESSING OR USING THE PLATFORM AND/OR SERVICES OR CLICKING
                    THAT YOU AGREE, YOU AGREE TO BE BOUND BY THESE TERMS OF SERVICE.
                  </span>
                </strong>
              </p>
              <ol>
                <li>
                  <strong>
                    <ul>
                      <li style={{ listStyleType: 'upper-alpha', fontSize: '10.25pt' }}>
                        <p style={{ margin: '5px 0' }}>
                          <span style={{ fontSize: '10.25pt' }}>PLATFORM SERVICES</span>
                        </p>
                      </li>
                    </ul>
                  </strong>
                </li>
              </ol>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>Gully6&nbsp;</span>
                <span style={{ fontSize: '10.25pt' }}>
                  inter alia provides an online interactive Platform for its viewers to experience Live streaming of matches,
                  match creation, tournament creation and management, team creation, sponsorships, insight and analytics of
                  players, individual profile, social networking, entertaining sports content, search and notification features
                  and other features, tools, applications, materials, or other services offered from time to time in connection
                  with sports business.&nbsp;
                </span>
                <strong>
                  <span style={{ fontSize: '10.25pt' }}>(“Material”)</span>
                </strong>
                <span style={{ fontSize: '10.25pt' }}>.&nbsp;</span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  Gully6 enable an interactive chat feature which may provide various services to the User(s) including but not
                  limited to posting of comments, videos, pictures and to discover, upload, view and share photos, videos, posts,
                  texts, comments, links and other content.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  The Platform also provides a sports marketplace for users to sell and/or purchase sports related goods and
                  materials and also allows user to list their services as an official, and make themselves available to be hired
                  and grounds can also be booked with this feature.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  (All such above services/features of the Platform collectively referred to as&nbsp;
                </span>
                <strong>
                  <span style={{ fontSize: '10.25pt' }}>&quotServices&quot</span>
                </strong>
                <span style={{ fontSize: '10.25pt' }}>).</span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <ol start={2}>
                <li style={{ listStyleType: 'upper-alpha', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <strong>
                      <u>
                        <span style={{ fontSize: '10.25pt' }}>LEGAL CONDITIONS&nbsp;</span>
                      </u>
                    </strong>
                  </p>
                </li>
              </ol>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  Your access and use of the Platform is expressly conditioned upon your agreement to these Terms of Service. If
                  you do not consent to these Terms of Service, you are not permitted to use any of the services offered by
                  Gully6.&nbsp;
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  These Terms of Service and Data Privacy Policy together constitute a binding agreement between you and
                  Gully6&nbsp;
                </span>
                <strong>
                  <span style={{ fontSize: '10.25pt' }}>(“Agreement”)</span>
                </strong>
                <span style={{ fontSize: '10.25pt' }}>
                  . The Data Privacy Policy is read with and incorporated into these Terms of Service and collectively govern your
                  use of the Platform.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  When you use or register for any of the services offered by Gully6, you become a user for purposes of this
                  Agreement and you agree to be bound by this Agreement and our&nbsp;
                </span>
                <Link to="/privacy-policies" style={{ textDecoration: 'none' }}>
                  <span style={{ fontSize: '10.25pt' }}>Privacy Policy</span>
                </Link>
                <span style={{ fontSize: '10.25pt' }}>
                  . If you access the Platform on behalf of a company or other entity, you warrant that you are an authorized
                  representative of such company or entity with the right to bind such company or entity to these Terms of Service
                  and Agreement.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  By accepting the Agreement, you affirm that you are 18 years of age or above and are fully competent to enter
                  into the Agreement, and to abide by and comply with the Agreement.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <ol start={3}>
                <li>
                  <strong>
                    <u>
                      <ul>
                        <li
                          style={{
                            listStyleType: 'upper-alpha',
                            fontSize: '10.25pt',
                          }}
                        >
                          <p style={{ margin: '10px 0' }}>
                            <span style={{ fontSize: '10.25pt' }}>PLATFORM MATERIAL</span>
                          </p>
                        </li>
                      </ul>
                    </u>
                  </strong>
                </li>
              </ol>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  All materials provided on the Platform, including but not limited to the Material defined above, information,
                  texts, logos, designs, graphics, videos, images, sounds, software, documents, products and services and the live
                  streaming material exclusively broadcasted by the user on the Platform and/or sports modules&nbsp;
                </span>
                <em>
                  <span style={{ fontSize: '10.25pt' }}>
                    (teams, match content, performance data etc. created on the Platform)
                  </span>
                </em>
                <span style={{ fontSize: '10.25pt' }}>
                  &nbsp;or any content created by the user using our Material (collectively, the&nbsp;
                </span>
                <strong>
                  <span style={{ fontSize: '10.25pt' }}>&quotMaterials&quot</span>
                </strong>
                <span style={{ fontSize: '10.25pt' }}>
                  ), and the selection, arrangement and display thereof, are the copyrighted works of the Gully6. All Materials
                  herein are proprietary to Gully6 and protected by worldwide copyright and other intellectual property
                  laws.&nbsp;
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  The live streaming content exclusively broadcasted by the user on the Platform and/or sports modules&nbsp;
                </span>
                <em>
                  <span style={{ fontSize: '10.25pt' }}>
                    (teams, match content, performance data etc. created on the Platform)
                  </span>
                </em>
                <span style={{ fontSize: '10.25pt' }}>
                  &nbsp;or any content created by the user using our Materials, such modules shall exclusively be published on the
                  Platform only and shall be covered under the Materials of Gully6 defined above.&nbsp;
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  Except as stated herein, none of the Materials may be modified, copied, reproduced, distributed, republished,
                  downloaded, displayed, sold, compiled, posted or transmitted in any form or by any means, including but not
                  limited to, electronic, mechanical, photocopying, recording or other means, without the prior express written
                  permission of Gully6.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <ol start={4}>
                <li>
                  <strong>
                    <u>
                      <ul>
                        <li
                          style={{
                            listStyleType: 'upper-alpha',
                            fontSize: '10.25pt',
                          }}
                        >
                          <p style={{ margin: '10px 0' }}>
                            <span style={{ fontSize: '10.25pt' }}>USER CONTENT</span>
                          </p>
                        </li>
                      </ul>
                    </u>
                  </strong>
                </li>
              </ol>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  The Platform may enable you to upload and transmit materials, texts, documents, photos, comments and videos or
                  other portions of the services and also provide you with access to discussion forums, blogs and other
                  interactive areas in which you or other users may post or transmit photos, videos, text, music, messages,
                  information, comments, labels, tags, descriptions, categorizations or other content or materials.&nbsp;
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  The Platform allows user to become a merchant on the Platform to sell/purchase sports related goods and
                  materials and sell sports services and modules. (collectively, the&nbsp;
                </span>
                <strong>
                  <span style={{ fontSize: '10.25pt' }}>“User Content/Content”</span>
                </strong>
                <span style={{ fontSize: '10.25pt' }}>).</span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  Your Content does not include the content created by you using the Materials and services of Gully6. Any such
                  content shall be broadcasted/published exclusively on the Platform and exclusively owned by Gully6 in
                  perpetuity.&nbsp;
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  Content is the responsibility of the person or entity that provides it. Gully6 is under no obligation to host or
                  serve Content. You retain ownership rights, title or interest in your Content. However, you agree to grant
                  certain rights to Gully6. By providing Content, you grant to Gully6 a worldwide, non-exclusive, royalty-free,
                  transferable, sublicensable license to use that Content (including to&nbsp;reproduce, distribute, prepare
                  derivative works,&nbsp;display and perform it). Gully6 may use that Content in connection with the services for
                  the purpose of&nbsp;broadcasting, promoting and redistributing&nbsp;part or all of&nbsp;the services.
                </span>
                <span style={{ fontSize: '10.25pt' }}>&nbsp;</span>
                <span style={{ fontSize: '10.25pt' }}>Gully6&nbsp;</span>
                <span style={{ fontSize: '10.25pt' }}>may also use your content to promote&nbsp;</span>
                <span style={{ fontSize: '10.25pt' }}>Platform&nbsp;</span>
                <span style={{ fontSize: '10.25pt' }}>and sell premium features.&nbsp;</span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  The licenses granted by you continue for a commercially reasonable period of time i.e. 36 months and/or as
                  allowed under law after you remove or delete your Content from the Platform. You understand and agree, however,
                  that Gully6 may retain in perpetuity, but not display, distribute, or perform, server copies of your Content
                  that have been removed or deleted.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <strong>
                  <span style={{ fontSize: '10.25pt' }}>Removal of Content</span>
                </strong>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>You may&nbsp;</span>
                {/* <a href="https://support.google.com/youtube/answer/55770"> */}
                <span style={{ fontSize: '10.25pt' }}>remove your Content</span>
                {/* </a> */}
                <span style={{ fontSize: '10.25pt' }}>
                  &nbsp;from the Platform at any time. If you see any Content which you believe violates any of the&nbsp;
                </span>
                {/* <a href="https://www.youtube.com/t/community_guidelines"> */}
                <span style={{ fontSize: '10.25pt' }}>guidelines</span>
                {/* </a> */}
                <span style={{ fontSize: '10.25pt' }}>&nbsp;or the applicable law, you can report it to Gully6.</span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  If we reasonably believe that any Content may cause harm to Gully6, our users, or third parties, Gully6 reserves
                  the right to delete any Content for any reason, without prior notice. Deleted content may be stored by Gully6 in
                  order to comply with certain legal obligations and is not retrievable without a valid court order.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  We will normally notify you with the reason for our action unless we reasonably believe that to do so: (a) would
                  breach the law or the direction of a legal enforcement authority or would otherwise risk legal liability for
                  Gully6 or our Affiliates; (b) would compromise an investigation or operation of the services; or (c) would cause
                  harm to any user, other third party, Gully6 or our Affiliates.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  Consequently, Gully6 encourages you to maintain your own backup of your Content. In other words, Gully6 is not a
                  backup service. Gully6 will not be liable to you for any modification, suspension, or discontinuation of the
                  Platform, or the loss of any Content.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <ol start={5}>
                <li>
                  <strong>
                    <u>
                      <ul>
                        <li
                          style={{
                            listStyleType: 'upper-alpha',
                            fontSize: '10.25pt',
                          }}
                        >
                          <p style={{ margin: '10px 0' }}>
                            <span style={{ fontSize: '10.25pt' }}>PERMISSIONS AND RESTRICTIONS</span>
                          </p>
                        </li>
                      </ul>
                    </u>
                  </strong>
                </li>
              </ol>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  You may use the Platform as made available to you for your personal and commercial as well as non-commercial
                  use, as long as you comply with the Agreement and applicable law. However, using the Platform does not give you
                  ownership of or rights to any aspect of the services, including user names, Materials or any other Content
                  posted by others or by Gully6.&nbsp;
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  The following restrictions apply to your use of the Platform. You are&nbsp;
                </span>
                <strong>
                  <u>
                    <span style={{ fontSize: '10.25pt' }}>not</span>
                  </u>
                </strong>
                <span style={{ fontSize: '10.25pt' }}>&nbsp;allowed to:</span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <ol>
                <li style={{ listStyleType: 'decimal', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>
                      access, copy, reproduce, download, distribute, transmit, broadcast, display, sell, license, alter, modify or
                      otherwise use any part of the services or any Content except with prior written permission from Gully6 and,
                      if applicable, the respective rights holders or otherwise permitted under this Agreement;&nbsp;
                    </span>
                  </p>
                </li>
                <li style={{ listStyleType: 'decimal', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>
                      circumvent, disable, fraudulently engage with, or otherwise interfere with any part of the Platform (or
                      attempt to do any of these things), including security-related features or features that (a) prevent or
                      restrict the copying or other use of Content or (b) limit the use of the services or Content;
                    </span>
                  </p>
                </li>
                <li style={{ listStyleType: 'decimal', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>
                      access or copy the Content using any manual or automated means including but not limited to robot, spider,
                      crawler, scraper, etc. or interface to access the Platform or extract other user’s information;
                    </span>
                  </p>
                </li>
                <li style={{ listStyleType: 'decimal', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>
                      use the Platform in a way that could interfere with, disrupt, negatively affect, or inhibit other users from
                      fully enjoying the services, or that could damage, disable, overburden, or impair the functioning of the
                      Platform.
                    </span>
                  </p>
                </li>
                <li style={{ listStyleType: 'decimal', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>
                      attempt to circumvent any content-filtering techniques employed by us, or attempt to access areas or
                      features of the Platform that you are not authorized to access.
                    </span>
                  </p>
                </li>
                <li style={{ listStyleType: 'decimal', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>
                      use or attempt to use another user’s account, username, or password without their permission.
                    </span>
                  </p>
                </li>
                <li style={{ listStyleType: 'decimal', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>solicit login credentials from another user.</span>
                  </p>
                </li>
                <li style={{ listStyleType: 'decimal', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>
                      use the Platform for any illegal or unauthorized purpose. International users agree to comply with all local
                      laws regarding online conduct and acceptable content.
                    </span>
                  </p>
                </li>
                <li style={{ listStyleType: 'decimal', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>
                      misuse any reporting, flagging, complaint, dispute, or appeals process, including by making groundless,
                      vexatious, or frivolous submissions;
                    </span>
                  </p>
                </li>
                <li style={{ listStyleType: 'decimal', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>
                      use the Platform to view or listen to Content other than for personal, non-commercial use;&nbsp;
                    </span>
                  </p>
                </li>
                <li style={{ listStyleType: 'decimal', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>
                      use the Platform to sell any advertising, sponsorships, or promotions placed on, around, or within the
                      Platform or Content, other than those specifically allowed in writing by Gully6.
                    </span>
                  </p>
                </li>
              </ol>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  The following restrictions apply to your submissions, and display of Content on Platform. You are&nbsp;
                </span>
                <strong>
                  <span style={{ fontSize: '10.25pt' }}>not</span>
                </strong>
                <span style={{ fontSize: '10.25pt' }}>&nbsp;allowed</span>
                <strong>
                  <span style={{ fontSize: '10.25pt' }}>&nbsp;</span>
                </strong>
                <span style={{ fontSize: '10.25pt' }}>submit, post and display on the Platform:&nbsp;</span>
              </p>
              <ol>
                <li style={{ listStyleType: 'decimal', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>
                      Content that are defamatory, malicious, obscene, intimidating, unlawful, offensive, discriminatory,
                      harassing or threatening or hate propaganda;
                    </span>
                  </p>
                </li>
                <li style={{ listStyleType: 'decimal', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>
                      Content that are abusive, harassing, threatening, impersonating or intimidating other Gully6 users;
                    </span>
                  </p>
                </li>
                <li style={{ listStyleType: 'decimal', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>
                      Content that promotes violence against, threaten, or harass other people on the basis of race, ethnicity,
                      national origin, caste, sexual orientation, gender, gender identity, religious affiliation, age, disability,
                      or serious disease;&nbsp;
                    </span>
                  </p>
                </li>
                <li style={{ listStyleType: 'decimal', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>
                      Content that are threatening or promotes terrorism or violent extremism;
                    </span>
                  </p>
                </li>
                <li style={{ listStyleType: 'decimal', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>Content that violates any law or regulation;</span>
                  </p>
                </li>
                <li style={{ listStyleType: 'decimal', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>
                      Content that attempts to target Gully6 or any of its users/affiliates;
                    </span>
                  </p>
                </li>
                <li style={{ listStyleType: 'decimal', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>
                      Content that is false or misleading, or promotes or encourages suicide or self-harm;
                    </span>
                  </p>
                </li>
                <li style={{ listStyleType: 'decimal', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>
                      Content that contains software viruses or any other computer code, files or programs designed to interrupt,
                      destroy or limit the functionality of any computer software or hardware or telecommunications equipment;
                    </span>
                  </p>
                </li>
                <li style={{ listStyleType: 'decimal', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>
                      Content that threatens the unity, integrity, defence, security or sovereignty of India, friendly relations
                      with foreign states, or public order or causes incitement to the commission of any cognizable offence or
                      prevents investigation of any offence or is insulting any other nation;
                    </span>
                  </p>
                </li>
                <li style={{ listStyleType: 'decimal', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>
                      Content that contains or links to pornography, graphic violence, threats, hate speech, or incitements to
                      violence;
                    </span>
                  </p>
                </li>
                <li style={{ listStyleType: 'decimal', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>
                      Content that violates any law or contractual or fiduciary relationships or that infringes any patent,
                      trademark, trade secret, copyright or other proprietary rights of any party;
                    </span>
                  </p>
                </li>
                <li style={{ listStyleType: 'decimal', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>
                      Content that is sexually explicit, obscene or content which violates privacy rights;&nbsp;
                    </span>
                  </p>
                </li>
                <li style={{ listStyleType: 'decimal', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>Content containing sensitive personal information;</span>
                  </p>
                </li>
                <li style={{ listStyleType: 'decimal', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>
                      Content that might disturb the reputation and goodwill of the Platform and Materials of Gully6 and its
                      employees/ affiliates/other users.
                    </span>
                  </p>
                </li>
              </ol>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <strong>
                  <u>
                    <span style={{ fontSize: '10.25pt' }}>E-PLATFORM MARKETPLACE</span>
                  </u>
                </strong>
              </p>
              <ol>
                <li style={{ listStyleType: 'decimal', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>
                      You agree, understand and acknowledge that the Platform is an online platform that also enables you to
                      sell/purchase products on the Platform at any time from any location. You further agree and acknowledge that
                      Gully6 is only a facilitator and is not and cannot be a party to or control in any manner any transactions
                      on the Platform. Accordingly, the contract of sale of products on the Platform shall be a strictly bipartite
                      contract between seller and the customer.&nbsp;
                    </span>
                  </p>
                </li>
                <li style={{ listStyleType: 'decimal', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>
                      Gully6 is not responsible or liable for the price, details, mandatory notices and information of the
                      products being offered for sale and doesn’t&nbsp;
                    </span>
                    <span style={{ backgroundColor: '#ffffff', fontSize: '10.25pt' }}>
                      guarantees related to the authenticity or genuineness of the products and/or the services provided.&nbsp;
                    </span>
                    <span style={{ fontSize: '10.25pt' }}>
                      Gully6 shall neither be responsible for the products listed on the Platform for sale or any customer
                      complaints or grievances against the goods and/or services offered.&nbsp;
                    </span>
                  </p>
                </li>
              </ol>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <ol start={3}>
                <li style={{ listStyleType: 'decimal', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>
                      If you are accessing the marketplace to buy products/services, purchasers you acknowledge and undertake that
                      you are accessing the services and transacting at your own risk and are using your best and prudent judgment
                      before entering into any transactions through the Platform. We shall neither be liable nor responsible for
                      any actions or inactions of sellers/ nor any breach of conditions, representations or warranties by the
                      sellers/ purchasers or manufacturers of the products and hereby expressly disclaim and any all
                      responsibility and liability in that regard. We shall not mediate or resolve any dispute or disagreement
                      between you and the sellers/ purchasers or manufacturers of the products.
                    </span>
                  </p>
                </li>
              </ol>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <ol start={4}>
                <li style={{ listStyleType: 'decimal', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>
                      We further expressly disclaim any warranties or representations (express or implied) in respect of quality,
                      suitability, accuracy, reliability, completeness, timeliness, performance, safety, merchantability, fitness
                      for a particular purpose, or legality of the products listed or displayed or transacted or the content
                      (including product or pricing information and/or specifications) on the Platform. While we have taken
                      precautions to avoid inaccuracies in content, this Platform, all content, information (including the price
                      of products), software, products, services and related graphics are provided as is, without warranty of any
                      kind. We do not implicitly or explicitly support or endorse the sale or purchase of any products on the
                      Platform. At no time shall any right, title or interest in the products sold through or displayed on the
                      Platform vest with Gully6 nor shall Gully6 have any obligations or liabilities in respect of any
                      transactions on the Platform.
                    </span>
                  </p>
                </li>
              </ol>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <ol start={5}>
                <li style={{ listStyleType: 'decimal', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>
                      We will not be responsible for any business loss (including loss of profits, revenue, contracts, anticipated
                      savings, data, goodwill or wasted expenditure) or any other indirect or consequential loss that is not
                      reasonably foreseeable to both you and us when you commenced using the Platform. You
                    </span>
                    <strong>
                      <span style={{ fontSize: '10.25pt' }}>&nbsp;</span>
                    </strong>
                    <span style={{ fontSize: '10.25pt' }}>
                      shall be responsible for payment of all fees/costs/charges associated with the purchase of products and you
                      agree to bear any and all applicable taxes including but not limited to VAT/CST, service tax, GST, duties
                      and cesses etc.
                    </span>
                  </p>
                </li>
              </ol>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <ol start={6}>
                <li>
                  <strong>
                    <u>
                      <ul>
                        <li
                          style={{
                            listStyleType: 'upper-alpha',
                            fontSize: '10.25pt',
                          }}
                        >
                          <p style={{ margin: '10px 0' }}>
                            <span style={{ fontSize: '10.25pt' }}>CHANGES TO THE PLATFORM</span>
                          </p>
                        </li>
                      </ul>
                    </u>
                  </strong>
                </li>
              </ol>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  Gully6 is constantly changing and improving its Platform. We may also need to alter or discontinue the Platform
                  and/or services, or any part of it, in order to make performance or security improvements, change functionality
                  and features, make changes to comply with law, or prevent illegal activities on or abuse of our systems or for
                  scheduled maintenance or upgrades, for emergency repairs, or due to failure of telecommunications links and
                  equipment that are beyond the control of Gully6. These changes may affect all users, some users or even an
                  individual user. &nbsp;Whenever reasonably possible, we will provide notice when we discontinue or make material
                  changes to our Platform or services that will have an adverse impact on the use of our services. However, you
                  understand and agree that there will be times when we make such changes without notice, such as where we feel we
                  need to take action to improve the security and operability of our Platform, prevent abuse, or comply with legal
                  requirements.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  We may stop (permanently or temporarily) providing the Platform/services or any features within the Platform to
                  you or to users generally. We also retain the right to create limits on use and storage at our sole discretion
                  at any time. We may also remove or refuse to distribute any Content on the Platform, limit distribution or
                  visibility of any Content on the service, suspend or terminate users, and reclaim usernames without liability to
                  you.&nbsp;
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <ol start={7}>
                <li style={{ listStyleType: 'upper-alpha', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <strong>
                      <u>
                        <span style={{ fontSize: '10.25pt' }}>ACCOUNT SECURITY</span>
                      </u>
                    </strong>
                  </p>
                </li>
              </ol>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  You are solely responsible to maintain the confidentiality of the passwords associated with your account and for
                  restricting access to your passwords and physical access to your computer while logged into the Platform. You
                  accept responsibility for all activities that occur under your user account.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  Content that you post, upload or otherwise make available via the Platform may be accessed, used and downloaded
                  by other users of the Platform. You understand and acknowledge that any Content contained in public areas of the
                  Platform is accessible to the public and could be accessed, downloaded, indexed, archived, linked to and
                  republished by others including, without limitation, appearing on other apps and in search engine results.&nbsp;
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  Gully6 use commercially reasonable security measures to protect your Content. We cannot, however, guarantee
                  absolute security of your account, your Content or the data we collect, and we cannot promise that our security
                  measures will prevent third party “hackers” or other unauthorized parties from illegally accessing the Platform
                  or contents. Gully6 is not responsible or liable for any third-party access to or use of the Content you post or
                  your data. You agree to immediately notify us of any unauthorized use of your account or passwords or any other
                  breach of security, and you accept all risks of unauthorized access to the data, Content and any other
                  information you provide to us.
                </span>
              </p>
              <ol start={8}>
                <li>
                  <strong>
                    <u>
                      <ul>
                        <li
                          style={{
                            listStyleType: 'upper-alpha',
                            fontSize: '10.25pt',
                          }}
                        >
                          <p style={{ margin: '10px 0' }}>
                            <span style={{ fontSize: '10.25pt' }}>ACCOUNT SUSPENSION &amp; TERMINATION</span>
                          </p>
                        </li>
                      </ul>
                    </u>
                  </strong>
                </li>
              </ol>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  You may stop using the Platform and our services at any time by unsubscribing/deactivating and removing your
                  content from Platform. Gully6 may suspend or terminate your use of the Platform, your account, or your account’s
                  access to all or part of the Platform if you materially or repeatedly breach these Terms of Service and/or acts,
                  deeds and conduct creates liability or harm to any user, other third party, Gully6 or its directors,
                  stakeholders, Affiliates, etc.
                </span>
              </p>
              <ol start={9}>
                <li>
                  <strong>
                    <u>
                      <ul>
                        <li
                          style={{
                            listStyleType: 'upper-alpha',
                            fontSize: '10.25pt',
                          }}
                        >
                          <p style={{ margin: '10px 0' }}>
                            <span style={{ fontSize: '10.25pt' }}>INTELLECTUAL PROPERTY RIGHTS</span>
                          </p>
                        </li>
                      </ul>
                    </u>
                  </strong>
                </li>
              </ol>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  You acknowledge and agree that the Materials on the Platform, other than the user’s Content that you licensed
                  under the Terms of Service, including without limitation, Materials and trademarks, service marks and logos
                  contained therein are owned by or licensed to Gully6, and are subject to copyright and other intellectual
                  property rights under Indian laws, foreign laws and international treaties and/or conventions. The Platform may
                  display certain trademarks belonging to third parties. Use of these trademarks may be subject to the license
                  granted by third parties to Gully6. You shall, in no event, reverse engineer, decompile, or disassemble such
                  trademarks and nothing herein shall be construed to grant you any right in relation to such trademarks.
                  Materials on the platform are provided to you AS IS for your information and may not be used, copied,
                  reproduced, distributed, transmitted, broadcast, displayed, sold, licensed, or otherwise exploited for any other
                  purposes whatsoever without the prior written consent of the Gully6.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  Gully6 reserves all rights not expressly granted herein to the Platform and the Materials. You agree to not
                  engage in the use, copying, or distribution of any of the Materials other than as expressly permitted herein,
                  including any use, copying, or distribution of Materials of third parties obtained through the Platform for any
                  commercial purposes. If you download or print a copy of the Materials for personal use, you must retain all
                  copyright and other proprietary notices contained therein. You agree not to circumvent, disable or otherwise
                  interfere with security-related features of the Platform or features that prevent or restrict use or copying of
                  any Materials or enforce limitations on use of the Platform or the Materials therein. The Service is protected
                  to the maximum extent permitted by copyright laws, other laws, and international treaties and/or conventions.
                  The content displayed on or through the Platform is protected by copyright as a collective work and/or
                  compilation, pursuant to copyrights laws, other laws, and international conventions. Any reproduction,
                  modification, creation of derivative works from or redistribution of the Platform, the Materials, or the
                  collective work or compilation is expressly prohibited. Copying or reproducing the Materials, or any portion
                  thereof to any other server or location for further reproduction or redistribution is expressly
                  prohibited.&nbsp;
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  You further agree not to reproduce, duplicate or copy content or Materials from the Platform, and agree to abide
                  by any and all copyright notices and other notices displayed on the Platform. Without limiting the foregoing,
                  you agree not to reproduce, duplicate, copy, sell, resell or exploit for any commercial purposes, any aspect of
                  the Platform.&nbsp;
                </span>
              </p>
              <ol start={10}>
                <li>
                  <strong>
                    <u>
                      <ul>
                        <li
                          style={{
                            listStyleType: 'upper-alpha',
                            fontSize: '10.25pt',
                          }}
                        >
                          <p style={{ margin: '10px 0' }}>
                            <span style={{ fontSize: '10.25pt' }}>TAKEDOWN POLICY</span>
                          </p>
                        </li>
                      </ul>
                    </u>
                  </strong>
                </li>
              </ol>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  Gully6 respects the intellectual property and personal rights of others, and we expect you to do the same.
                  Gully6’s Terms of Service do not allow posting, sharing, or sending any content that violates or infringes
                  someone else’s personality, copyrights, trademarks or other intellectual property rights.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <strong>
                  <u>
                    <span style={{ fontSize: '10.25pt' }}>Copyright-&nbsp;</span>
                  </u>
                </strong>
                <span style={{ fontSize: '10.25pt' }}>
                  Copyright is a legal right that protects original works of authorship (e.g., music, videos, etc.). Generally,
                  copyright protects an original expression of an idea but does not protect underlying ideas or facts.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <strong>
                  <u>
                    <span style={{ fontSize: '10.25pt' }}>Copyright Infringement-&nbsp;</span>
                  </u>
                </strong>
                <span style={{ fontSize: '10.25pt' }}>
                  We do not allow any content that infringes copyright. Any use of copyrighted content of others without proper
                  authorization or legally valid reason may lead to a violation. In some cases, you may be required to provide
                  evidence to prove that you are entitled to use copyrighted content of others.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <strong>
                  <u>
                    <span style={{ fontSize: '10.25pt' }}>Removal of Content; Suspension or Termination of Account-&nbsp;</span>
                  </u>
                </strong>
                <span style={{ fontSize: '10.25pt' }}>
                  Any user content that infringes other person’s copyright may be removed. Your account may be suspended or
                  terminated for multiple copyright violations in connection with your use of the Platform, or other violations of
                  the Terms of Service.&nbsp;
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <strong>
                  <u>
                    <span style={{ fontSize: '10.25pt' }}>Copyright Infringement Notification-&nbsp;</span>
                  </u>
                </strong>
                <span style={{ fontSize: '10.25pt' }}>
                  If you believe that your copyright-protected work was posted on Platform, or otherwise hosted by Gully6 without
                  authorization, you may submit a copyright infringement notification.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>The notification must include the following:</span>
              </p>
              <ol>
                <li style={{ listStyleType: 'lower-alpha', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>
                      An email address, physical address and a phone number so that we and the uploader of your work can contact
                      you.
                    </span>
                  </p>
                </li>
                <li style={{ listStyleType: 'lower-alpha', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>
                      A clear and complete description of your work that you believe has been infringed.
                    </span>
                  </p>
                </li>
                <li style={{ listStyleType: 'lower-alpha', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>
                      The URL or other identifying location of the allegedly infringing work.
                    </span>
                  </p>
                </li>
                <li style={{ listStyleType: 'lower-alpha', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>
                      A statement that you have a good faith belief that use of the material in the manner complained of is not
                      authorized by the copyright owner, its agent, or law.
                    </span>
                  </p>
                </li>
                <li style={{ listStyleType: 'lower-alpha', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>
                      A statement made under penalty of perjury that the above information is accurate, and that you are the
                      copyright owner or are authorized to act on behalf of the copyright owner.
                    </span>
                  </p>
                </li>
                <li style={{ listStyleType: 'lower-alpha', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <span style={{ fontSize: '10.25pt' }}>
                      The physical or electronic signature of the copyright owner or an authorized representative of the copyright
                      owner. To satisfy this requirement, you may type your full legal name (not that of a company) at the bottom
                      of your notification.
                    </span>
                  </p>
                </li>
              </ol>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>Submit the above information to us:</span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>By Email:&nbsp;</span>
                <span style={{ backgroundColor: '#ffff00', fontSize: '10.25pt' }}>&nbsp;</span>
                <a href="mailto:info@myysports.com">
                  <span style={{ backgroundColor: '#ffff00', fontSize: '10.25pt' }}>info@myysports.com</span>
                </a>
                <span style={{ backgroundColor: '#ffff00', fontSize: '10.25pt' }}>&nbsp;/</span>
                <span style={{ backgroundColor: '#ffff00', fontSize: '10.25pt' }}>support@myysports.com</span>
                <span style={{ fontSize: '10.25pt' }}>; or compliances@myysports.com</span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  By Mail: Please submit the above information to our registered office at&nbsp;
                </span>
                <span style={{ fontSize: '10.25pt' }}>:&nbsp;</span>
                <span
                  style={{
                    color: '#202124',
                    backgroundColor: '#ffffff',
                    fontSize: '10.25pt',
                  }}
                >
                  FIEE Complex, A66, 1st Floor Okhla Phase II, New Delhi, Delhi 110020
                </span>
                <span style={{ fontSize: '10.25pt' }}>.</span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  All information submitted in your copyright infringement notification may be forwarded to the uploader of the
                  content or otherwise made public in any way by Gully6 or a third party.&nbsp;
                </span>
                <u>
                  <span style={{ fontSize: '10.25pt' }}>
                    Before submitting a notification, please be aware of that intentionally submitting a misleading or fraudulent
                    report may lead to liability for damages.
                  </span>
                </u>
              </p>
              <p style={{ margin: '10px 0' }}>
                <strong>
                  <u>
                    <span style={{ fontSize: '10.25pt' }}>Copyright Infringement Counter-Notification-&nbsp;</span>
                  </u>
                </strong>
                <span style={{ fontSize: '10.25pt' }}>
                  If you receive a copyright infringement notification that you believe to be in error, you may provide us with a
                  counter-notification.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>The counter-notification must include the following:</span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>a.</span>
                <span style={{ fontSize: '10.25pt' }}>&nbsp; &nbsp;&nbsp;</span>
                <span style={{ fontSize: '10.25pt' }}>
                  Your contact information, including your full legal name (not that of a company), an email address, a physical
                  address, and a phone number. If you are an authorized representative of the uploader, such as an attorney,
                  please be sure to specify your relationship to the uploader.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>b.</span>
                <span style={{ fontSize: '10.25pt' }}>&nbsp; &nbsp;&nbsp;</span>
                <span style={{ fontSize: '10.25pt' }}>
                  Identify the location, including any URL, of the work that has been removed or disabled.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>c.</span>
                <span style={{ fontSize: '10.25pt' }}>&nbsp; &nbsp;&nbsp;</span>
                <span style={{ fontSize: '10.25pt' }}>
                  Add the following statement: &quotI swear, under penalty of perjury, that I have a good faith belief that the
                  material was removed or disabled as a result of a mistake or misidentification of the material to be removed or
                  disabled.&quot
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>d.</span>
                <span style={{ fontSize: '10.25pt' }}>&nbsp; &nbsp;&nbsp;</span>
                <span style={{ fontSize: '10.25pt' }}>
                  Your physical or electronic signature. To satisfy this requirement, you may type your full legal name (not that
                  of a company) at the bottom of your electronic counter-notification.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>Submit the above information to us:</span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>By Email:&nbsp;</span>
                <span style={{ backgroundColor: '#ffff00', fontSize: '10.25pt' }}>&nbsp;info@myysports.com /</span>
                <span style={{ backgroundColor: '#ffff00', fontSize: '10.25pt' }}>support@myysports.com</span>
                <span style={{ fontSize: '10.25pt' }}>; or compliances@myysports.com</span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  By Mail: Please submit the above information to our registered office at&nbsp;
                </span>
                <span style={{ fontSize: '10.25pt' }}>:&nbsp;</span>
                <span
                  style={{
                    color: '#202124',
                    backgroundColor: '#ffffff',
                    fontSize: '10.25pt',
                  }}
                >
                  FIEE Complex, A66, 1st Floor Okhla Phase II, New Delhi, Delhi 110020
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  The counter-notification process may take no less than twenty-one (21) business days to complete, please be
                  patient. During this time, the copyright claimant may file an action seeking a court order to keep the content
                  down. Please note that we will forward the entire counter-notification to the copyright claimant, including any
                  personal information you provide, and the counter-notification may be made public. The claimant may use this
                  information to file a lawsuit against you.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  If we do not receive notice within twenty-one (21) business days that the original reporter is seeking a court
                  order to prevent further infringement of the material at issue, we may replace or cease disabling access to the
                  material that was removed. The decision to re-post any material is at Gully6’s sole discretion.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <strong>
                  <u>
                    <span style={{ fontSize: '10.25pt' }}>Trademark and Personal Rights</span>
                  </u>
                </strong>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  A trademark is a word, symbol, slogan, design, or combination of any of the foregoing that identifies the source
                  of a product or service and distinguishes it from other products or services.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  Gully6’s policies prohibit any content that infringes on another person’s trademark or impersonates another
                  person’s name, likeness and persona. Using another person’s trademark in a way that may mislead or confuse
                  people to believe that you are affiliated with the trademark owner may be a violation of our Terms of Service.
                  Further, using another person’s name, likeness and persona may amount to impersonation and may be a violation of
                  our Terms of Service.&nbsp;
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  However, using another person’s trademark for the purpose of accurately referencing the trademark owner’s
                  products or services, or to compare them to other products or services, or use of another person’s name,
                  likeness and persona to identify the said person is generally not considered a violation of our Terms of
                  Service.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <strong>
                  <u>
                    <span style={{ fontSize: '10.25pt' }}>Removal of Content; Suspension or Termination of Account</span>
                  </u>
                </strong>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  Any user content that infringes other person’s trademark or personality rights may be removed. Your account may
                  be suspended or terminated for multiple trademark violations in connection with your use of the Gully6 Platform,
                  or other violations of the Terms of Service.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>Trademark Infringement/Personality Rights Notification</span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  Gully6 may investigate reports that are submitted by the trademark holder or their authorized representative or
                  the person being impersonated. You can submit a trademark report to us through Trademark Infringement Report or
                  via email (
                </span>
                <span style={{ backgroundColor: '#ffff00', fontSize: '10.25pt' }}>compliance@myysports.com</span>
                <span style={{ fontSize: '10.25pt' }}>), by providing the following information:</span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>a.</span>
                <span style={{ fontSize: '10.25pt' }}>&nbsp; &nbsp;&nbsp;</span>
                <span style={{ fontSize: '10.25pt' }}>
                  Your contact information, including your full name, physical address, email address and a phone number. Please
                  note that we may provide your information and details of your report to the person who posted the content or
                  owns the account you are reporting. This person may contact you with the information you provide.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>b.</span>
                <span style={{ fontSize: '10.25pt' }}>&nbsp; &nbsp;&nbsp;</span>
                <span style={{ fontSize: '10.25pt' }}>Your relationship to the trademark owner or the person impersonated.</span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>c.</span>
                <span style={{ fontSize: '10.25pt' }}>&nbsp; &nbsp;&nbsp;</span>
                <span style={{ fontSize: '10.25pt' }}>
                  A clear and complete description of your trademark that you believe has been infringed, including the
                  jurisdiction of registration, registration number, trademarked goods and services class.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>d.</span>
                <span style={{ fontSize: '10.25pt' }}>&nbsp; &nbsp;&nbsp;</span>
                <span style={{ fontSize: '10.25pt' }}>
                  A scan of trademark registration certificate or a direct link (URL) to your trademark record.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>e.</span>
                <span style={{ fontSize: '10.25pt' }}>&nbsp; &nbsp;&nbsp;</span>
                <span style={{ fontSize: '10.25pt' }}>
                  Information about the allegedly infringing content, including the URL or other identifying location of the
                  allegedly infringing content, and a description of how the content has been infringing your trademark or
                  personality rights.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>f.</span>
                <span style={{ fontSize: '10.25pt' }}>&nbsp; &nbsp;&nbsp;</span>
                <span style={{ fontSize: '10.25pt' }}>
                  A statement that you have a good faith belief that use of the trademark described above, in the manner
                  complained of, is not authorized by the trademark owner, its agent, or the law or the person whose personal
                  rights have been allegedly violated.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>g.</span>
                <span style={{ fontSize: '10.25pt' }}>&nbsp; &nbsp;&nbsp;</span>
                <span style={{ fontSize: '10.25pt' }}>
                  A statement made under penalty of perjury that the above information is accurate, and that you are the trademark
                  owner or are authorized to act on behalf of the owner.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>h.</span>
                <span style={{ fontSize: '10.25pt' }}>&nbsp; &nbsp;&nbsp;</span>
                <span style={{ fontSize: '10.25pt' }}>
                  The physical or electronic signature of the trademark owner or an authorized representative of the trademark
                  owner or the person whose personal rights have been allegedly violated.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>i.</span>
                <span style={{ fontSize: '10.25pt' }}>&nbsp; &nbsp;&nbsp;</span>
                <span style={{ fontSize: '10.25pt' }}>
                  If we remove content in response to a report of trademark infringement or violation of personality rights, we
                  may notify the person you reported to inform them the content was removed. We may also provide them with your
                  contact information, including your email address and the name of the trademark owner, and/or details of your
                  report.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <strong>
                  <u>
                    <span style={{ fontSize: '10.25pt' }}>General Note</span>
                  </u>
                </strong>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  As a Gully6 user, you are responsible for the content you post. If you have questions about copyright law or
                  trademark law, such as questions about whether your content or your use of another person’s name or brand
                  infringes or otherwise violates another person’s rights, you may want to contact an attorney. If you are unsure
                  whether the material you plan to report to us is infringing or otherwise violating another person’s right, you
                  may also want to first seek legal advice before reporting such content to us.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  If you submit a report or infringement notification to us, we may contact you if we have additional questions
                  about your report or notification. Please note that Gully6 is not in a position to adjudicate disputes between
                  third parties and may not be able to remove the content or suspend the account you reported for infringement or
                  violation. As an alternative, you may want to contact the person who posted the content or owns the account to
                  try to resolve your issue directly.
                </span>
              </p>
              <ol start={11}>
                <li>
                  <strong>
                    <u>
                      <ul>
                        <li
                          style={{
                            listStyleType: 'upper-alpha',
                            fontSize: '10.25pt',
                          }}
                        >
                          <p style={{ margin: '10px 0' }}>
                            <span style={{ fontSize: '10.25pt' }}>WARRANTY DISCLAIMER</span>
                          </p>
                        </li>
                      </ul>
                    </u>
                  </strong>
                </li>
              </ol>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <strong>
                  <span style={{ fontSize: '10.25pt' }}>
                    OTHER THAN AS EXPRESSLY STATED IN THIS AGREEMENT&nbsp;OR AS REQUIRED BY LAW, THE PLATFORM IS PROVIDED “AS IS”
                    AND GULLY6 DOES NOT MAKE ANY SPECIFIC COMMITMENTS OR WARRANTIES ABOUT THE PLATFORM.
                  </span>
                </strong>
              </p>
              <p style={{ margin: '10px 0' }}>
                <strong>
                  <span style={{ fontSize: '10.25pt' }}>
                    GULLY6 DOES NOT REPRESENT OR WARRANT THAT THE MATERIALS OR THE PLATFORM ARE ACCURATE, COMPLETE, RELIABLE,
                    CURRENT OR ERROR-FREE OR THAT THE PLATFORM, ITS SERVERS OR EMAIL SENT FROM GULLY6 OR THE PLATFORM ARE FREE OF
                    VIRUSES OR OTHER HARMFUL COMPONENTS. GULLY6 IS NOT RESPONSIBLE FOR TYPOGRAPHICAL ERRORS OR OMISSIONS RELATING
                    TO TEXT, PHOTOS OR VIDEOS. GULLY6 ALSO MAKES NO REPRESENTATION OR WARRANTY REGARDING THE AVAILABILITY,
                    RELIABILITY OR SECURITY OF THE PLATFORM AND WILL NOT BE LIABLE FOR ANY UNAUTHORIZED ACCESS TO OR ANY
                    MODIFICATION, SUSPENSION, UNAVAILABILITY, OR DISCONTINUANCE OF THE PLATFORM OR THE PRODUCTS PROVIDED
                    THEREON.&nbsp;
                  </span>
                </strong>
              </p>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <ol start={12}>
                <li>
                  <strong>
                    <u>
                      <ul>
                        <li
                          style={{
                            listStyleType: 'upper-alpha',
                            fontSize: '10.25pt',
                          }}
                        >
                          <p style={{ margin: '10px 0' }}>
                            <span style={{ fontSize: '10.25pt' }}>LIMITATION OF LIABILITY</span>
                          </p>
                        </li>
                      </ul>
                    </u>
                  </strong>
                </li>
              </ol>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <strong>
                  <span style={{ fontSize: '10.25pt' }}>
                    IN NO EVENT WILL GULLY6 OR ITS DIRECTORS, MEMBERS, EMPLOYEES OR AGENTS BE LIABLE FOR ANY SPECIAL, INDIRECT OR
                    CONSEQUENTIAL DAMAGES, OR ANY OTHER DAMAGES OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, LOSS OF USE, LOSS OF
                    PROFITS OR LOSS OF DATA, WHETHER IN AN ACTION IN CONTRACT, TORT OR OTHERWISE, ARISING OUT OF OR IN ANY WAY
                    CONNECTED WITH THE USE OF OR INABILITY TO USE OR VIEW THE PLATFORM, THE SERVICES, THE PRODUCTS, THE CONTENT OR
                    THE MATERIALS CONTAINED IN OR ACCESSED THROUGH THE PLATFORM, INCLUDING ANY DAMAGES CAUSED BY OR RESULTING FROM
                    YOUR RELIANCE ON ANY INFORMATION OBTAINED FROM GULLY6, OR THAT RESULT FROM MISTAKES, OMISSIONS, INTERRUPTIONS,
                    DELETION OF FILES OR EMAIL, ERRORS, DEFECTS, VIRUSES, DELAYS IN OPERATION OR TRANSMISSION OR ANY TERMINATION,
                    SUSPENSION OR OTHER FAILURE OF PERFORMANCE, WHETHER OR NOT RESULTING FROM ACTS OF GOD, COMMUNICATIONS FAILURE,
                    THEFT, DESTRUCTION OR UNAUTHORIZED ACCESS TO GULLY6’s RECORDS, PROGRAMS OR SERVICES OR PRODUCTS.
                  </span>
                </strong>
              </p>
              <p style={{ margin: '10px 0' }}>
                <strong>
                  <span style={{ fontSize: '10.25pt' }}>
                    IN NO EVENT WILL THE AGGREGATE LIABILITY OF GULLY6, WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE,
                    WHETHER ACTIVE, PASSIVE OR IMPUTED), PRODUCT LIABILITY, STRICT LIABILITY OR OTHER THEORY, ARISING OUT OF OR
                    RELATING TO THE USE OF OR INABILITY TO USE THE PLATFORM, THE SERVICES, THE PRODUCTS, THE CONTENT OR THE
                    MATERIALS, EXCEED COMPENSATION YOU PAY, IF ANY, TO GULLY6 FOR ACCESS TO OR USE OF THE PLATFORM OR THE
                    SERVICES.&nbsp;
                  </span>
                </strong>
              </p>
              <ol start={13}>
                <li>
                  <strong>
                    <u>
                      <ul>
                        <li
                          style={{
                            listStyleType: 'upper-alpha',
                            fontSize: '10.25pt',
                          }}
                        >
                          <p style={{ margin: '10px 0' }}>
                            <span style={{ fontSize: '10.25pt' }}>INDEMNITY</span>
                          </p>
                        </li>
                      </ul>
                    </u>
                  </strong>
                </li>
              </ol>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  You agree, to the extent permitted by law, to indemnify, defend, and hold harmless&nbsp;Gully6, its affiliates,
                  directors, officers, shareholders, employees, licensors, and agents from and against any and all complaints,
                  charges, claims, damages, losses, costs, liabilities, and expenses (including attorneys’ fees) due to, arising
                  out of, or relating in any way to your access to or use of the Platform and/or your content and/or your breach
                  of terms of the Agreement.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <ol start={14}>
                <li>
                  <strong>
                    <u>
                      <ul>
                        <li
                          style={{
                            listStyleType: 'upper-alpha',
                            fontSize: '10.25pt',
                          }}
                        >
                          <p style={{ margin: '10px 0' }}>
                            <span style={{ fontSize: '10.25pt' }}>ASSIGNMENT</span>
                          </p>
                        </li>
                      </ul>
                    </u>
                  </strong>
                </li>
              </ol>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  The Agreement including these Terms of Services are binding upon and inure to the benefit of the parties hereto
                  and their permitted successors and assigns. Notwithstanding the foregoing, you may not assign your rights under
                  these Terms of Services without Gully6’s prior written consent. Gully6 will be permitted to assign its rights
                  under these Terms of Services at its sole discretion.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <ol start={15}>
                <li style={{ listStyleType: 'upper-alpha', fontSize: '10.25pt' }}>
                  <p style={{ margin: '10px 0' }}>
                    <strong>
                      <u>
                        <span style={{ fontSize: '10.25pt' }}>THIRD-PARTY LINKS</span>
                      </u>
                    </strong>
                  </p>
                </li>
              </ol>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  The Platform may contain links to third-party apps and online services that are not owned or controlled by
                  Gully6. We have no control over, and assumes no responsibility for, such apps and online services.&nbsp;
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <ol start={16}>
                <li>
                  <strong>
                    <u>
                      <ul>
                        <li
                          style={{
                            listStyleType: 'upper-alpha',
                            fontSize: '10.25pt',
                          }}
                        >
                          <p style={{ margin: '10px 0' }}>
                            <span style={{ fontSize: '10.25pt' }}>MODIFICATION OF THE AGREEMENT</span>
                          </p>
                        </li>
                      </ul>
                    </u>
                  </strong>
                </li>
              </ol>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  Gully6 reserves the right to change, modify, revise or otherwise amend any provision of these Terms of Service,
                  Data Privacy Policy and any other terms, policies, at any time at its sole discretion by providing notice that
                  the Terms of Service have been modified. Such notice may be provided by sending an email, or by posting a notice
                  on the Platform, or by posting the revised Terms of Service on the Platform and revising the date at the bottom
                  of these Terms of Service or by such other form of notice as determined by Gully6. Your use of the Platform or
                  your purchase of new services following the posting of the revised Terms of Services or other notice will
                  constitute your acceptance of such changes or modifications. Otherwise, any changes or modifications will be
                  effective within thirty (30) days of the posting of the revisions on the Platform unless you notify Gully6
                  within such thirty (30) days that you do not agree to the changes and stop using the Platform. Therefore, you
                  should review these Terms of Services whenever you access the Platform and at least every thirty (30) days to
                  make sure that you understand the terms and conditions that will apply to your use of the Platform.
                </span>
              </p>
              <ol start={17}>
                <li>
                  <strong>
                    <u>
                      <ul>
                        <li
                          style={{
                            listStyleType: 'upper-alpha',
                            fontSize: '10.25pt',
                          }}
                        >
                          <p style={{ margin: '10px 0' }}>
                            <span style={{ fontSize: '10.25pt' }}>SEVERANCE</span>
                          </p>
                        </li>
                      </ul>
                    </u>
                  </strong>
                </li>
              </ol>
              <p style={{ margin: '10px 0' }}>
                <strong>
                  <span style={{ fontSize: '10.25pt' }}>&nbsp;</span>
                </strong>
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  If any particular term of this Agreement is not enforceable for any reason, this will not affect any other
                  terms.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <ol start={18}>
                <li>
                  <strong>
                    <u>
                      <ul>
                        <li
                          style={{
                            listStyleType: 'upper-alpha',
                            fontSize: '10.25pt',
                          }}
                        >
                          <p style={{ margin: '10px 0' }}>
                            <span style={{ fontSize: '10.25pt' }}>NO WAIVER</span>
                          </p>
                        </li>
                      </ul>
                    </u>
                  </strong>
                </li>
              </ol>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  If you fail to comply with this Agreement and no immediate action is taken by Gully6, this does not mean that
                  Gully6 is giving up any rights that it may have such as the right to take action in the future.
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <ol start={19}>
                <li>
                  <strong>
                    <u>
                      <ul>
                        <li
                          style={{
                            listStyleType: 'upper-alpha',
                            fontSize: '10.25pt',
                          }}
                        >
                          <p style={{ margin: '10px 0' }}>
                            <span style={{ fontSize: '10.25pt' }}>GOVERNING LAW</span>
                          </p>
                        </li>
                      </ul>
                    </u>
                  </strong>
                </li>
              </ol>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  This Agreement shall be governed, interpreted, construed and enforced in accordance with the laws of India. The
                  courts at New Delhi, India shall entertain all disputes arising out of or relating to Agreement including these
                  Terms of Service, Data Privacy Policy and other policies etc. of Gully6, regardless of conflict of laws rules.
                  These disputes will be resolved exclusively in the courts at New Delhi, India and you have consented to
                  exclusive jurisdiction of courts at New Delhi, India.&nbsp;
                </span>
              </p>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <ol start={20}>
                <li>
                  <strong>
                    <u>
                      <ul>
                        <li
                          style={{
                            listStyleType: 'upper-alpha',
                            fontSize: '10.25pt',
                          }}
                        >
                          <p style={{ margin: '10px 0' }}>
                            <span style={{ fontSize: '10.25pt' }}>SURVIVAL</span>
                          </p>
                        </li>
                      </ul>
                    </u>
                  </strong>
                </li>
              </ol>
              <p style={{ margin: '10px 0' }}>
                <br />
              </p>
              <p style={{ margin: '10px 0' }}>
                <span style={{ fontSize: '10.25pt' }}>
                  The Terms of Service which by their nature are intended to survive termination or expiration of Platform
                  including, but not limited to, Indemnification, Warranty Disclaimer, Governing Law and the Limitation of
                  Liability will survive any expiration or termination of the Agreement.
                </span>
              </p>
            </>
          </Typography>
        </Box>
      </MainWidthWrapper>
    </>
  );
};

export default TermsAndConditions;
