import { createSlice } from '@reduxjs/toolkit';
const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: false,
    user: [],
    userProfile: null,
    loading: false,
    error: null,
    nextAuthAction: null,
    firebaseUser: null,
    showAuthWall: true,
    emailSuccess: false,
    isWebView: false,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = !state.loading;
    },
    setUserProfile: (state, action) => {
      state.userProfile = action.payload;
    },
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setNextAuthAction: (state, action) => {
      state.nextAuthAction = action.payload;
    },
    setFirebaseUser: (state, action) => {
      state.firebaseUser = action.payload;
    },
    toggleAuthWallOn: (state, action) => {
      state.showAuthWall = true;
    },
    toggleAuthWallOff: (state, action) => {
      state.showAuthWall = false;
    },
    clearUser: (state, action) => {
      state.user = [];
    },
    setWebview: (state, action) => {
      state.isWebView = action.payload;
    },
  },
});

export const {
  setFirebaseUser,
  setUser,
  setError,
  setLoading,
  setUserProfile,
  setIsAuthenticated,
  setNextAuthAction,
  toggleAuthWallOn,
  toggleAuthWallOff,
  clearUser,
  setWebview,
} = authSlice.actions;
export default authSlice.reducer;
