import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UserInfo from './ui/userinfo';
import './css/userProfile.css';
import Vector from './../assets/Vector.png';
import tos from './../assets/tos.png';
import privacy from './../assets/privacy.png';
import help from './../assets/help.png';
import Logout from './../assets/logout.png';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import TermsAndConditions from '../pages/policies/termsAndConditions';
import PrivacyPolicy from '../pages/policies/privacyPolicy';
import { logout } from '../redux/auth/auth.action';
import MainWidthWrapper from './MainWidthWrapper';

const UserProfile = (showAuthWallHandler) => {
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const { isWebView } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname === '/terms-of-service') {
      setShowTerms(true);
    } else if (location.pathname === '/privacy-policy') {
      setShowPrivacy(true);
    } else {
      setShowTerms(false);
      setShowPrivacy(false);
    }
  }, [location.pathname]);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleTermsClick = () => {
    navigate('/terms-of-service');
  };

  const handlePrivacyClick = () => {
    navigate('/privacy-policy');
  };

  const handleLogoutClick = () => {
    dispatch(logout());
    // window.location.href = '/';
    navigate('/');
  };

  return (
    <div>
      <MainWidthWrapper>
        {showTerms ? (
          <TermsAndConditions />
        ) : showPrivacy ? (
          <PrivacyPolicy />
        ) : (
          <div className="user-settings-container">
            <div className="back-button" style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
              <img
                src={Vector}
                alt="Back Arrow"
                style={{ width: '14px', height: '14px', borderRadius: '50%' }}
                onClick={handleBackClick}
              />
              <span style={{ marginLeft: '8px', display: 'inline-block' }}>Your Details</span>
            </div>

            <div>
              <UserInfo />
            </div>
            <div className="settings-list">
              <div className="settings-group">
                <div className="legal">Legal</div>
                <ul
                  style={{
                    listStyle: 'none',
                    gap: '10px',
                    color: '#FFFFFF',
                  }}
                >
                  <li
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '42px',
                      gap: '0px',
                      fontSize: '13px',
                      fontWeight: '400',
                      lineHeight: '15.23px',
                      letterSpacing: '0.2px',
                      textAlign: 'left',
                      color: '#0A0A0A',
                      padding: '12px 2px',
                      cursor: 'pointer',
                    }}
                    onClick={handleTermsClick}
                  >
                    <img src={tos} alt="TOS Icon" style={{ width: '16px', height: '18px', marginRight: '8px' }} />
                    Terms of services
                  </li>
                  <li
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '42px',
                      gap: '0px',
                      fontSize: '13px',
                      fontWeight: '400',
                      lineHeight: '15.23px',
                      letterSpacing: '0.2px',
                      textAlign: 'left',
                      color: '#0A0A0A',
                      padding: '12px 2px',
                      cursor: 'pointer',
                    }}
                    onClick={handlePrivacyClick}
                  >
                    <img src={privacy} alt="Privacy Policy Icon" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
                    Privacy policy
                  </li>
                </ul>
              </div>

              <div className="settings-group">
                <div className="personal">Personal</div>
                <ul>
                  <li
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '42px',
                      gap: '0px',
                      fontSize: '13px',
                      fontWeight: '400',
                      lineHeight: '15.23px',
                      letterSpacing: '0.2px',
                      textAlign: 'left',
                      color: '#0A0A0A',
                      padding: '12px 2px',
                    }}
                    onClick={() => {
                      window.open('https://wa.me/919958162727?text=Hi,%20I%20need%20support!', '_blank');
                    }}
                  >
                    <img
                      src={help}
                      alt="Privacy Policy Icon"
                      style={{ width: '16px', height: '16px', marginRight: '8px', cursor: 'pointer' }}
                    />
                    Need help, chat with us
                  </li>

                  {!isWebView ? (
                    <li
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '42px',
                        gap: '0px',
                        fontSize: '13px',
                        fontWeight: '400',
                        lineHeight: '15.23px',
                        letterSpacing: '0.2px',
                        textAlign: 'left',
                        color: '#0A0A0A',
                        padding: '12px 2px',
                      }}
                    >
                      <img src={Logout} alt="Privacy Policy Icon" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
                      <span style={{ color: '#DF513D' }}>
                        <button onClick={handleLogoutClick}>Log out</button>
                      </span>
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
          </div>
        )}
      </MainWidthWrapper>
    </div>
  );
};

export default UserProfile;
