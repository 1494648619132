import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import MainWidthWrapper from './MainWidthWrapper';
import { fetchAllMatches } from '../redux/match/match.action';
import { clearGetAllMatches, setSelectedDate } from './../redux/match/match.slice';
import CalendarView from './CalenderView';
import MatchCard from './ui/MatchCard';
import matchConfig from './config/matchcard.config';
import { toggleAuthWallOff, toggleAuthWallOn } from '../redux/auth/auth.slice';
import ArrowUpIcon from '@mui/icons-material/ArrowUpward';

const Match = () => {
  const [currentPage, setPage] = useState(1);
  const [showUpArrow, setShowUpArrow] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const selectedDate = useSelector((state) => state.match.date);
  const dispatch = useDispatch();
  const getAllMatches = useSelector((state) => state.match.getAllMatches, shallowEqual);
  const { pageSize, totalPages } = useSelector((state) => state.match);
  const { isAuthenticated, user, isWebView } = useSelector((state) => state.auth);
  const userObjId = user?.userObjId;
  const [activeButton, setActiveButton] = useState(null);
  const sentinelRef = useRef(null);
  const observer = useRef(null);

  useEffect(() => {
    setPage(1);
  }, [selectedDate, activeButton]);

  const handleButtonClick = (buttonType) => {
    setActiveButton((prev) => (prev === buttonType ? '' : buttonType));
    setPage(1);
    dispatch(clearGetAllMatches());
  };

  useEffect(() => {
    if (currentPage <= totalPages && !isFetching) {
      setIsFetching(true);
      const date = selectedDate !== null ? selectedDate : undefined;
      const tournament = activeButton === 'Tournaments' || activeButton === 'My Tournaments' ? 'tournament' : undefined;
      const userId = activeButton === 'My Tournaments' ? userObjId : undefined;

      dispatch(fetchAllMatches(currentPage, pageSize, date, tournament, userId)).finally(() => {
        setIsFetching(false);
      });
    }
  }, [dispatch, currentPage, pageSize, selectedDate, activeButton, userObjId]);

  const handleIntersection = useCallback(
    async (entries) => {
      const entry = entries[0];
      if (entry.isIntersecting && !isFetching) {
        setPage((prev) => prev + 1);
      }
    },
    [isFetching]
  );

  useEffect(() => {
    if (observer.current) observer.current.disconnect();
    if (sentinelRef.current) {
      observer.current = new IntersectionObserver(handleIntersection, {
        rootMargin: '100px',
        threshold: 1.0,
      });
      observer.current.observe(sentinelRef.current);
    }
  }, [handleIntersection]);

  useEffect(() => {
    dispatch(toggleAuthWallOff());
    return () => {
      dispatch(toggleAuthWallOn());
      dispatch(clearGetAllMatches());
    };
  }, [dispatch]);

  const handleCalendarDateSelect = (date) => {
    setPage(1);
    dispatch(setSelectedDate(date));
  };

  const handleScroll = useCallback(() => {
    setShowUpArrow(window.scrollY > 400);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const isLoading = isFetching && currentPage === 1;

  const matchConfigData = Array.isArray(getAllMatches) ? getAllMatches.map((match) => matchConfig(match)) : [];

  return (
    <>
      <MainWidthWrapper>
        <div
          className={`${isWebView ? 'sticky top-[0px] z-[1000] bg-white px-2' : 'sticky top-[42px] z-[1000] bg-white px-2'} `}
          style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px' }}
        >
          <div className="flex items-center mb-3 ml-1">
            <span className="mr-2 text-sm font-semibold leading-[16.41px] text-left mt-[20px]">MATCHES</span>
            <hr className="flex-1 border-[0.5px] border-black mt-[15px]" />
          </div>
          <div className="flex justify-center w-full">
            <div className="w-full">
              <CalendarView setCurrentPage={setPage} onSelectDate={handleCalendarDateSelect} className="w-full" />
            </div>
          </div>
        </div>
        <div
          className={`${
            isWebView
              ? 'sticky top-[88px] z-[100] flex justify-start py-3  bg-white'
              : 'sticky top-[128px] z-[100] flex justify-start py-3  bg-white'
          }`}
        >
          <button
            onClick={() => handleButtonClick('Tournaments')}
            className={`bg-white border border-[#E2E8F0] px-[15px] py-[12px] rounded-t-[8px] rounded-b-[8px] shadow-[1px_1px_8px_0px_#E2E8F0] text-[12px] bold h-[28px] flex items-center justify-center mr-[12px] ml-[8px]
            ${activeButton === 'Tournaments' ? '[&&]:bg-[#DBE1FF]' : 'hover:[&&]:bg-white-200'}`}
          >
            Tournaments
          </button>
          {isAuthenticated && (
            <button
              onClick={() => handleButtonClick('My Tournaments')}
              className={`bg-white border border-[#E2E8F0] px-[15px] py-[12px] rounded-t-[8px] rounded-b-[8px] shadow-[1px_1px_8px_0px_#E2E8F0] text-[12px] bold h-[28px] flex items-center justify-center
              ${activeButton === 'My Tournaments' ? '[&&]:bg-[#DBE1FF]' : 'hover:[&&]:bg-white-200'}`}
            >
              My Tournaments
            </button>
          )}
          <div className="ml-auto flex items-center justify-center">
            {showUpArrow && (
              <button
                onClick={scrollToTop}
                className="flex items-center justify-center w-6 h-7 bg-gray-300 text-white rounded-md shadow-lg hover:bg-gray-300 mr-5"
              >
                <ArrowUpIcon sx={{ color: 'black', fontSize: '18px' }} />
              </button>
            )}
          </div>
        </div>
        <div className="px-2 py-2">{getAllMatches && <MatchCard matches={matchConfigData} loading={isLoading} />}</div>
        <div ref={sentinelRef} className="h-1 w-full" />
      </MainWidthWrapper>
    </>
  );
};

export default Match;
