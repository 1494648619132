import React, { useRef, useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import './css/player.css';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const VideoPlayer = ({ videoUrl, onPlayPause, matchId }) => {
  const [playing, setPlaying] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState('');
  const matchCardThumbnail = `https://gc-media-01.s3.ap-south-1.amazonaws.com/${matchId}/thumbnail_${matchId}_matchcard.webp`;
  const fallbackThumbnailUrl =
    'https://media.istockphoto.com/id/502037149/photo/cricket-action.jpg?s=612x612&w=0&k=20&c=zKWIx8c-5BGepNLpmWsQOoaNkZCEAdLr3KT_r8Y8maI=';
  const [loadedVideoUrl, setLoadedVideoUrl] = useState(null);
  const [videoError, setVideoError] = useState(false);
  const playerRef = useRef(null);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    const checkThumbnail = () => {
      const img = new Image();
      img.src = matchCardThumbnail;
      img.onload = () => setThumbnailUrl(matchCardThumbnail);
      img.onerror = () => setThumbnailUrl(fallbackThumbnailUrl);
    };
    checkThumbnail();
  }, [matchCardThumbnail]);

  const handleOverlayClick = (e) => {
    navigate(`/match/${matchId}`);
  };

  const handlePlayButtonClick = (e) => {
    e.stopPropagation();
    setPlaying(true);
    setLoadedVideoUrl(videoUrl);
    if (onPlayPause) onPlayPause(true);
  };

  const handlePlayPause = (newPlayingState) => {
    setPlaying(newPlayingState);
    if (onPlayPause) onPlayPause(newPlayingState);
  };

  useEffect(() => {
    if (playerRef.current && (!isAuthenticated || loadedVideoUrl)) {
      playerRef.current.seekTo(0, 'seconds');
    }
  }, [loadedVideoUrl, isAuthenticated]);

  const handleVideoError = () => {
    setVideoError(true);
  };

  return (
    <div className="video-player-wrapper">
      <div className="video-player-container" style={{ position: 'relative' }} onClick={handleOverlayClick}>
        {videoError ? (
          <div className="video-unavailable-message">Video Unavailable</div>
        ) : (
          <Box sx={{ position: 'relative' }}>
            {!playing && (
              <div
                className="thumbnail-overlay"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundImage: `url(${thumbnailUrl})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <div
                  className="play-button"
                  onClick={handlePlayButtonClick}
                  style={{
                    zIndex: 2,
                    position: 'relative',
                  }}
                >
                  <FontAwesomeIcon icon={faPlay} size="3x" color="white" />
                </div>
              </div>
            )}

            <ReactPlayer
              ref={playerRef}
              url={loadedVideoUrl}
              playing={isAuthenticated && playing}
              controls={true}
              width="100%"
              height="180px"
              onPlay={() => handlePlayPause(true)}
              onPause={() => handlePlayPause(false)}
              onError={handleVideoError}
            />
          </Box>
        )}
      </div>
    </div>
  );
};

export default VideoPlayer;
