import React from 'react';
import Navbar from './Navbar';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const PlayerRegistrationForm = () => {
  return (
    <>
      <div className="w-full bg-white">
        <div className="w-full flex justify-center bg-white">
          <Helmet>
            <script src="https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js"></script>
          </Helmet>
          <iframe
            id="JotFormIFrame-243532234244045"
            title="Player Registration"
            onLoad={() => window.parent.scrollTo(0, 0)}
            allow="geolocation; microphone; camera; fullscreen"
            src="https://form.jotform.com/243532234244045"
            frameBorder="0"
            style={{ width: '100%', height: '400vh', border: 'none' }}
            scrolling="no"
          ></iframe>
          <script>{`window.jotformEmbedHandler("iframe[id='JotFormIFrame-243532234244045']", "https://form.jotform.com/");`}</script>
        </div>
      </div>
    </>
  );
};

export default PlayerRegistrationForm;
