import React, { useState } from 'react';
import Navbar from './Navbar';
import { useNavigate, useSearchParams } from 'react-router-dom';

const TeamRegistrationForm = () => {
  const [searchParams] = useSearchParams();
  const isWebView = searchParams.get('webview') === 'true';
  const navigate = useNavigate();
  const [activeForm, setActiveForm] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handlelogoClick = () => {
    navigate('/');
  };

  const handleBatClick = () => {
    navigate('/');
  };

  const handleInfoclick = () => {
    navigate('/about-us');
  };

  const handleprivacyClick = () => {
    navigate('/privacy-policy');
  };

  const handleTeamFormClick = () => {
    navigate('/team-registration');
  };

  const handlePlayerFormClick = () => {
    navigate('/player-registration');
  };

  const toggleForm = (formIndex) => {
    setIsLoading(true);
    setActiveForm(activeForm === formIndex ? null : formIndex);
  };

  return (
    <div className="relative w-full h-screen bg-white">
      {isLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-16 h-16 border-4 border-t-blue-500 border-gray-200 rounded-full animate-spin"></div>
        </div>
      )}
      {!isWebView ? (
        <Navbar
          onlogoClick={handlelogoClick}
          onBatClick={handleBatClick}
          onInfoclick={handleInfoclick}
          onPrivacyClick={handleprivacyClick}
        />
      ) : null}
      <div className="flex flex-col w-full h-full items-center justify-center bg-white pb-10">
        <div className="text-center">
          <a
            href="https://forms.gle/H7Xe1C5wPWfFnaYQ6"
            className="block px-3 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mb-[25px] text-sm md:text-lg md:mb-[30px] md:w-100"
            onClick={() => toggleForm(1)}
          >
            Box Dhamaka Tournament Registration
          </a>
        </div>

        <div className="text-center">
          <button
            className="block px-14 py-2 bg-green-500 text-white rounded hover:bg-green-600 mb-[25px] text-sm md:text-lg md:mb-[30px] md:w-100"
            onClick={handlePlayerFormClick}
          >
            Player Details Registration
          </button>
        </div>

        <div className="text-center">
          <button
            className="block px-14 py-2 bg-red-500 text-white rounded hover:bg-red-600 mb-[25px] text-sm md:text-lg md:mb-[30px] md:w-100"
            onClick={handleTeamFormClick}
          >
            Team Details Registration
          </button>
        </div>
      </div>
    </div>
  );
};

export default TeamRegistrationForm;
