import { Box } from '@mui/system';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Navbar from './Navbar';
import UserProfile from './userProfile';
import TournamentList from './tournamentlist';
import AuthWrapper from './AuthWrapper';
import { toggleAuthWallOff, toggleAuthWallOn, setWebview } from './../redux/auth/auth.slice';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import Match from './Matches';
import CreationForm from './Team-Creation/CreationForm';
import Footer from './footer';
import { ToastContainer } from 'react-toastify';
import AboutUsPage from '../pages/policies/AboutUs';
import PrivacyPolicy from '../pages/policies/privacyPolicy';
import Cookies from 'js-cookie';
import { verifyAccessToken } from '../redux/auth/auth.action';
const Layout = () => {
  const [showMatches, setShowMatches] = useState(true);
  const [showUserSettings, setShowUserSettings] = useState(false);
  const [showTeam, setShowTeam] = useState(false);
  const [showTournamentList, setShowTournamentList] = useState(false);
  const [showaboutuspage, setShowAboutuspage] = useState(false);
  const [showprivacypolicy, setShowprivacypolicy] = useState(false);
  const { isAuthenticated, isWebView } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const isWebView = searchParams.get('webview') === 'true';
    const accessToken = searchParams.get('accessToken');
    const refreshToken = searchParams.get('refreshToken');
    if (isWebView && accessToken && refreshToken) {
      Cookies.set('accessToken', accessToken, {
        secure: true,
        sameSite: 'Strict',
        expires: 1,
      });
      Cookies.set('refreshToken', refreshToken, {
        secure: true,
        sameSite: 'Strict',
        expires: 3,
      });
      dispatch(verifyAccessToken());
      dispatch(setWebview(isWebView));
    }
  }, [dispatch]);

  const navigateToDefault = () => {
    navigate('/', { replace: true });
  };

  const handlelogoClick = () => {
    navigate('/', { replace: true });
  };

  const handleBatClick = () => {
    navigate('/', { replace: true });
  };

  const handleAvatarClick = () => {
    if (!isAuthenticated) {
      dispatch(toggleAuthWallOn());
    } else {
      navigate('/user-profile');
    }
  };

  const handleAwardClick = () => {
    if (!isAuthenticated) {
      dispatch(toggleAuthWallOff());
      navigate('/tournaments', { replace: true });
    } else {
      navigate('/tournaments');
    }
  };

  const handleTeamClick = () => {
    if (!isAuthenticated) {
      dispatch(toggleAuthWallOn());
    }
    navigate('/team-creation');
  };

  const handleInfoclick = () => {
    navigate('/about-us');
  };

  const handleprivacyClick = () => {
    navigate('/privacy-policy');
  };

  useEffect(() => {
    const currentPath = location.pathname;
    switch (currentPath) {
      case '/':
        setShowMatches(true);
        setShowUserSettings(false);
        setShowTeam(false);
        setShowTournamentList(false);
        setShowprivacypolicy(false);
        setShowAboutuspage(false);
        break;
      case '/user-profile':
        setShowUserSettings(true);
        setShowMatches(false);
        setShowTeam(false);
        setShowTournamentList(false);
        setShowprivacypolicy(false);
        setShowAboutuspage(false);
        break;
      case '/tournaments':
        setShowTournamentList(true);
        setShowMatches(false);
        setShowUserSettings(false);
        setShowTeam(false);
        setShowprivacypolicy(false);
        setShowAboutuspage(false);
        break;
      case '/team-creation':
        setShowTeam(true);
        setShowMatches(false);
        setShowUserSettings(false);
        setShowTournamentList(false);
        setShowprivacypolicy(false);
        setShowAboutuspage(false);
        break;
      case '/about-us':
        setShowAboutuspage(true);
        setShowMatches(false);
        setShowUserSettings(false);
        setShowTournamentList(false);
        setShowprivacypolicy(false);
        break;
      case '/privacy-policy':
        setShowprivacypolicy(true);
        setShowMatches(false);
        setShowUserSettings(false);
        setShowTournamentList(false);
        setShowAboutuspage(false);
        break;
      default:
        navigateToDefault();
        break;
    }
  }, [location.pathname, navigate]);

  return (
    <>
      <ToastContainer />
      <AuthWrapper isWebView={isWebView}>
        {!isWebView ? (
          <div className="sticky top-0 z-50">
            <Navbar
              onlogoClick={handlelogoClick}
              onBatClick={handleBatClick}
              onAvatarClick={handleAvatarClick}
              onAwardClick={handleAwardClick}
              onTeamClick={handleTeamClick}
              onInfoclick={handleInfoclick}
              onPrivacyClick={handleprivacyClick}
            />
          </div>
        ) : null}
        <div className="flex-1 pt-[0px]">
          <Box sx={{ pt: 0 }}>
            {showMatches ? (
              <Match />
            ) : showTournamentList ? (
              <TournamentList />
            ) : showUserSettings ? (
              <UserProfile />
            ) : showTeam ? (
              <CreationForm />
            ) : showaboutuspage ? (
              <AboutUsPage />
            ) : showprivacypolicy ? (
              <PrivacyPolicy />
            ) : null}
          </Box>
        </div>
        <Footer />
      </AuthWrapper>
    </>
  );
};

export default Layout;
