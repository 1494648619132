import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const BackIcon = () => {
  const navigate = useNavigate();
  const { isWebView } = useSelector((state) => state.auth);

  const handleBackClick = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      console.log('No previous page in the history');
    }
  };

  return (
    <Tooltip title={'BACK'}>
      <span>
        <IconButton
          onClick={handleBackClick}
          disabled={window.history.length <= 1}
          aria-label="back"
          sx={{
            display: isWebView ? 'none' : 'flex',
            alignItems: 'center',
          }}
        >
          <ArrowBackIcon sx={{ color: 'white', fontSize: '13px' }} />
          <Typography sx={{ color: 'white', marginLeft: '4px', fontSize: '12px' }}>BACK</Typography>
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default BackIcon;
